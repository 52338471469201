import {
    USER_REGISTER,
    USER_REGISTER_QUERY,
    USER_LOGIN,
    USER_LOGIN_QUERY,
    USER_VERIFY,
    USER_PREVERIFY_LOGIN,
    USER_VERIFY_QUERY,
    USER_VERIFY_FAILED,
    USER_CHANGE_PASSWORD,
    USER_CHANGE_PASSWORD_QUERY,
    USER_UPDATE_VALUES,
    USER_UPDATE_VALUES_QUERY,
    USER_UPDATE_PROFILE_IMAGE,
    USER_UPDATE_PROFILE_IMAGE_QUERY,
    USER_LOGIN_ATTEMPT_FAILED,
    USER_REGISTER_ATTEMPT_FAILED,
    USER_PROFILE_IMAGE_UPDATE_FAILED,
    USER_PROFILE_SUBMIT_ATTEMPT_FAILED,
    USER_NEW_EMAIL_VERIFICATION_REQUEST_STARTED,
    USER_NEW_EMAIL_VERIFICATION_REQUEST_COMPLETE,
    USER_LOGOUT,
    USER_UPDATE_NOTIFICATION_SETTINGS,
    USER_UPDATE_NOTIFICATION_SETTINGS_QUERY,
    USER_UPDATE_NOTIFICATION_SETTINGS_VALUES,
    USER_UPDATE_CONTENT
} from '../actions/actionTypes';
import cookieService from '../../services/cookieService';

const initalState = {
    authToken: "",
    userLoginCanSubmit: true,
    userRegisterCanSubmit: true,
    userLoginProcessComplete: false,
    userProfileDetailsCanUpdate: true,
    userPasswordCanChange: true,
    userCanUseForgotPassword: true,
    userCanUpdateProfileImage: true,
    userCanRequestNewVerificationEmail: true,
    userCanUpdateNotificationSettings: true,
//    userLoggedIn: false,
//    userVerified: false,
//    userconfirmed: false,
//    userPreVerified: false,
    user: {},
    menu: [],
    options: {}
};

function UserReducer(state = initalState, action) {
    switch(action.type) {
        case USER_REGISTER_QUERY:
            var user = Object.assign({}, state);
            user.userRegisterCanSubmit = false;
            return user;
        case USER_REGISTER:
            var user = Object.assign({}, state);

            user.authToken = action.token;
            user.user = action.user;
            user.userLoginCanSubmit = true;
            user.userLoggedIn = true;
            user.userPreVerified = true;
            user.userVerified = action.verified;
            user.userConfirmed = action.userConfirmed;

            var saveObj = {};
            var saveUser = {};

            saveUser.ID = user.user.ID;
            saveUser.points = user.user.points;
            saveUser.profile = user.user.profile;

            saveObj.user = saveUser;
            saveObj.token = user.authToken;
            saveObj.verified = user.userVerified;
            saveObj.confirmed = user.userconfirmed;

            cookieService.setCookie('asc_user', JSON.stringify(saveObj));

            return user;
        case USER_REGISTER_ATTEMPT_FAILED:
            var user = Object.assign({}, state);

            user.userRegisterCanSubmit = true;

            return user;
        case USER_LOGIN_QUERY:
            var user = Object.assign({}, state);
            
            user.userLoginCanSubmit = false;

            return user;
        case USER_LOGIN:
            var user = Object.assign({}, state);

            user.authToken = action.token;
            user.user = action.user;
            user.userLoginCanSubmit = true;
            user.userLoggedIn = true;
            user.userPreVerified = true;
            user.userVerified = action.verified;
            user.userConfirmed = action.confirmed;
            
            var saveObj = {};
            var saveUser = {};

            saveUser.ID = user.user.ID;
            saveUser.points = user.user.points;
            saveUser.profile = user.user.profile;

            saveObj.user = saveUser;
            saveObj.token = user.authToken;
            saveObj.verified = user.userVerified;
            saveObj.confirmed = user.userConfirmed;
            
            cookieService.setCookie('asc_user', JSON.stringify(saveObj));

            return user;
        case USER_LOGIN_ATTEMPT_FAILED:
            var user = Object.assign({}, state);

            user.userLoginCanSubmit = true;

            return user;
        case USER_VERIFY_QUERY:
            return state;
        case USER_PREVERIFY_LOGIN:
            var user = Object.assign({}, state);
            
            user.authToken = action.user.token;
            user.user = action.user.user;
            user.userLoggedIn = true;

            user.userVerified = action.user.verified;
            user.userConfirmed = action.user.confirmed;
            user.userPreVerified = true;

            return user;
        case USER_VERIFY:
            var user = Object.assign({}, state);

            user.user = action.user;
            user.userLoggedIn = true;
            user.userVerified = action.verified;
            user.userConfirmed = action.confirmed;
            user.userLoginProcessComplete = true;

            user.userLoginCanSubmit = true;

            var saveObj = {};
            var saveUser = {};

            saveUser.ID = user.user.ID;
            saveUser.points = user.user.points;
            saveUser.profile = user.user.profile;

            saveObj.user = saveUser;
            saveObj.token = user.authToken;
            saveObj.verified = user.userVerified;
            saveObj.confirmed = user.userConfirmed;

            cookieService.setCookie('asc_user', JSON.stringify(saveObj));
            
            return user;
        case USER_UPDATE_CONTENT:
            var user = Object.assign({}, state);

            user.user = action.newUser;
            
            var saveObj = {};
            var saveUser = {};

            saveUser.ID = user.user.ID;
            saveUser.points = user.user.points;
            saveUser.profile = user.user.profile;

            saveObj.user = saveUser;
            saveObj.token = user.authToken;
            saveObj.verified = user.userVerified;
            saveObj.confirmed = user.userConfirmed;

            cookieService.setCookie('asc_user', JSON.stringify(saveObj));

            return user;
        case USER_VERIFY_FAILED:
            var user = Object.assign({}, state);

            user.user = {};
            user.userPreVerified = false;
            user.userLoggedIn = false;
            user.userVerified = false;
            user.userConfirmed = false;
            user.userLoginCanSubmit = true;
            user.userRegisterCanSubmit = true;
            user.userLoginProcessComplete = true;
            user.authToken = "";
            user.options = {};
            user.menu = [];
            cookieService.setCookie('asc_user', '');

            return user;
        case USER_LOGOUT:
            var user = Object.assign({}, state);

            user.user = {};
            user.userLoggedIn = false;
            user.userVerified = false;
            user.userconfirmed = false;
            user.userLoginCanSubmit = true;
            user.userRegisterCanSubmit = true;
            user.authToken = "";
            user.options = {};
            user.menu = [];
            cookieService.setCookie('asc_user', '');

            return user;
        case USER_UPDATE_VALUES:
            var user = Object.assign({}, state);

            user.user = action.user;
            user.userProfileDetailsCanUpdate = true;

            return user;
        case USER_UPDATE_VALUES_QUERY:
            var user = Object.assign({}, state);

            user.userProfileDetailsCanUpdate = false;

            return user;
        case USER_UPDATE_PROFILE_IMAGE_QUERY:
            var user = Object.assign({}, state);

            user.userCanUpdateProfileImage = false;

            return user;
        case USER_UPDATE_PROFILE_IMAGE:
            var user = Object.assign({}, state);
            
            user.user.profile = action.image;
            user.userCanUpdateProfileImage = true;

            return user;
        case USER_PROFILE_IMAGE_UPDATE_FAILED:
            var user = Object.assign({}, state);

            user.userCanUpdateProfileImage = true;

            return user;
        case USER_CHANGE_PASSWORD:
            var user = Object.assign({}, state);

            user.userPasswordCanChange = true;

            return user;
        case USER_CHANGE_PASSWORD_QUERY:
            var user = Object.assign({}, state);

            user.userPasswordCanChange = false;

            return user;
        case USER_NEW_EMAIL_VERIFICATION_REQUEST_STARTED:
            var user = Object.assign({}, state);

            user.userCanRequestNewVerificationEmail = false;

            return user;
        case USER_NEW_EMAIL_VERIFICATION_REQUEST_COMPLETE:
            var user = Object.assign({}, state);

            user.userCanRequestNewVerificationEmail = true;

            return user;
        case USER_UPDATE_NOTIFICATION_SETTINGS:
            var user = Object.assign({}, state);

            user.userCanUpdateNotificationSettings = true;            

            return user;
        case USER_UPDATE_NOTIFICATION_SETTINGS_QUERY:
            var user = Object.assign({}, state);

            user.userCanUpdateNotificationSettings = false;

            return user;
        case USER_UPDATE_NOTIFICATION_SETTINGS_VALUES:
            var user = Object.assign({}, state);

            user.user.notificationSettings = action.nSettings;

            return user;
        default:
            return state;
    }
}

export default UserReducer;