import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import './DashboardAnnouncement.scss';

function DownArrow(props) {
    return(
        <svg width={12} height={7} xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M6.398 6.838l5.437-5.535a.565.565 0 000-.799L11.502.17a.562.562 0 00-.797 0L6 4.973 1.295.166a.562.562 0 00-.797 0L.165.499a.565.565 0 000 .8l5.437 5.535c.22.22.576.22.796.004z"
            fill="#1C2E3B"
            fillRule="nonzero"
        />
        </svg>
    );
}

function DashboardAnnouncement() {
	const content = useSelector((state) => state.content.pages?.dashboard);
    var [closed, setClosed] = useState(true);
    var [width, setWidth] = useState(window.innerWidth);
    var displayClosed = closed;
    var classes = "DashboardAnnouncement";
    var container = useRef();
    var height = '0px';

    if(window.innerWidth > 699) {
        displayClosed = false;
    }

    if(displayClosed) {
        classes += " closed";
    }

    function handleClick() {
        if(window.innerWidth < 700) {
            setClosed(!closed);
        }
    }

    function handleResize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    })

    return (
        <div className={classes} onClick={handleClick} ref={container}>
            <img className="background-image" src={content?.announcement?.background_image.url} alt={content?.announcement?.background_image.alt} />
            <h2><span>{content?.announcement?.heading}</span><DownArrow /></h2>
            {!displayClosed &&
            <p className="bold-copy">{content?.announcement?.bold_copy}</p>
            }
            {!displayClosed &&
            <p className="copy">{content?.announcement?.copy}</p>
            }

        </div>
    );
}

export default DashboardAnnouncement;
