import "./LeftUserSidebarCopyRight.scss";
import React from 'react';

function LeftUserSidebarCopyRight() {
    var year = new Date().getFullYear().toString();

    return(
        <div className="LeftUserSidebarCopyRight">
            © Marlin Spring, {year}. All Rights Reserved
        </div>
    );
}

export default LeftUserSidebarCopyRight;