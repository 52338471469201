import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { userLogin } from '../../redux/actions/userActions';
import Form from '../Form/Form';

function LoginForm(props) {
    var formLayout = props.form;
    var dispatch = useDispatch();
    var submitting = useSelector(state => state.user.userLoginCanSubmit);

    const [formValue, setFormValue] = useState({
        ac_user: '',
        ac_password: '',
        ac_remember: true
    });

    function hasErrors() {
        for(var i = 0; i < formLayout.length; i++) {
            if(formLayout[i]?.error === true) return true;
        }

        return false;
    }

    function loginUser(values) {
        dispatch(userLogin(values));
    }

    return(
        <Form
            fields={props.form}
            classes="LoginForm"
            onSubmit={loginUser}
            submitting={!submitting}
        />
    )
}

export default LoginForm;