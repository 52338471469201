import React from 'react';

export function EmailIcon(props) {
    return (
        <svg width={20} height={16} xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M20 2c0-1.1-.9-2-2-2H2C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2zm-2 0l-8 5-8-5h16zm0 12H2V4l8 5 8-5v10z"
                fill="#1C2E3B"
                fillRule="nonzero"
            />
        </svg>
    )
}

export function ArrowIcon(props) {
    return (
        <svg width={18} height={18} xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M16 16H2V2h7V0H2a2 2 0 00-2 2v14a2 2 0 002 2h14c1.1 0 2-.9 2-2V9h-2v7zM11 0v2h3.59l-9.83 9.83 1.41 1.41L16 3.41V7h2V0h-7z"
                fill="#1C2E3B"
                fillRule="nonzero"
            />
        </svg>
    )
}

export function MapIcon(props) {
    return (
        <svg width={22} height={27} xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M11 0c6.075 0 11 4.79 11 10.697 0 5.1-3.669 9.365-8.58 10.437l-2.49 4.98L8.421 21.1C3.59 19.97 0 15.74 0 10.697 0 4.79 4.925 0 11 0zm.172 8C9.558 8 8.25 9.269 8.25 10.833c0 1.565 1.308 2.834 2.922 2.834s2.922-1.269 2.922-2.834C14.094 9.27 12.786 8 11.172 8z"
                fill="#1C2E3B"
                fillRule="evenodd"
            />
        </svg>
    )
}

export function GreyPlusIcon(props) {
    return (
        <svg width={13} height={12} xmlns="http://www.w3.org/2000/svg" {...props}>
            <g transform="translate(.6)" fill="#C2CCD4" fillRule="evenodd">
                <rect x={5.2} width={1.6} height={12} rx={0.5} />
                <rect
                transform="rotate(90 6 6)"
                x={5.2}
                width={1.6}
                height={12}
                rx={0.5}
                />
            </g>
        </svg>
    )
}