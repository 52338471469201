import {
    POPUP_ADD_MESSAGE,
    POPUP_GET_NEXT_MESSAGE,
    POPUP_CLOSE_POPUP
} from './actionTypes';

export function addPopupMessage(message) {
    return {
        type: POPUP_ADD_MESSAGE,
        message: message
    }
}

export function closePopupMessageWindow() {
    return {
        type: POPUP_CLOSE_POPUP
    }
}

export function nextPopupMessage() {
    return {
        type: POPUP_GET_NEXT_MESSAGE
    }
}