import "./AccountVerified.scss";
import React, { useEffect, useState } from 'react';
import LeftSidebar from "../LeftSidebar/LeftSidebar";
import LeftSidebarLight from "../LeftSidebarLight/LeftSidebarLight";
import LeftSideBarPrivacyNav from '../LeftSideBarPrivacyNav/LeftSideBarPrivacyNav';
import LeftUserSidebarCopyRight from '../LeftUserSidebarCopyRight/LeftUserSidebarCopyRight';
import UserLogout from "../UserLogout/UserLogout";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function AccountVerified(props) {
    var content = useSelector((state) => state.content.pages?.account_verified);
    var history = useHistory();
    var loggedIn = useSelector(state => state.user.userLoggedIn);
    var verified = useSelector(state => state.user.userVerified);
    var preVerified = useSelector(state => state.user.preVerified);
    var confirmed = useSelector(state => state.user.userConfirmed);

    var [winWidth, setWinWidth] = useState(window.innerWidth);
    function setWidth() {
        setWinWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', setWidth);
        return () => window.removeEventListener('resize', setWidth);
    });

    if(loggedIn === true) {
        if(verified === true) {
            if(confirmed) {
                history.push('/dashboard');
            }
        }
        else {
            history.push('/account-created');
        }
    }
    else if(!loggedIn && !verified && !confirmed && !preVerified) history.push('/');

    return (
        <div className="AccountVerified">
            <LeftSidebar />
            <LeftSidebarLight>
                {winWidth > 899 && 
                    <LeftSideBarPrivacyNav blue />
                }
                <UserLogout />
                {winWidth > 899 &&
                    <LeftUserSidebarCopyRight />
                }
            </LeftSidebarLight>
            <div className="content-container">
                <div className="account-verified-wrapper">
                    <img src="/img/email_open_icon.svg" alt="" />
                    <h2 className="heading" dangerouslySetInnerHTML={{__html: content.gold_header}}></h2>
                    <p>{content.gold_copy}</p>
                </div>
            </div>
        </div>
    )
}

export default AccountVerified;