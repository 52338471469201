import React from 'react';

function LogoutIcon() {
    return(
        <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path
                    className="fill-path"
                    d="M17 8l-1.41 1.41L17.17 11H9v2h8.17l-1.58 1.58L17 16l4-4-4-4zM5 5h7V3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h7v-2H5V5z"
                    fill="#1C2E3B"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    );
}

export default LogoutIcon;