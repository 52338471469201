import React, { useEffect, componentDidMount, useState } from 'react';
import { askForPermissionToReceiveNotifications } from '../Firebase/Firebase';
import './DashboardNotificationBar.scss';

function DashboardNotificationBar(props) {
	useEffect(() => {
	    if (!props.isBarHidden) {
	    	askForPermissionToReceiveNotifications(props.setBarHidden);
	    }
	}, []);

    return (
		<div className="DashboardNotificationBar">
			{!props.isBarHidden &&
				<div
					className="notification-bar"
					onClick={() => 
						askForPermissionToReceiveNotifications(props.setBarHidden)
					}
				>
					Click Here to Receive Notifications
				</div>
			}
		</div>
	);
}

export default DashboardNotificationBar;
