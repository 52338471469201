import {
    CONTENT_QUERY,
    CONTENT_UPDATE,
} from './actionTypes';
import apiService from '../../services/apiService';

function content_notify() {
    return {
        type: CONTENT_QUERY
    }
}

function updateContent(content) {
    for(var i = 0; i < content.options.merges.length; i++) {
        var merge = content.options.merges[i];
        var mergeParent;
        var mergeName;
        
        var developmentRemove;
        var selectRemove;


        for(var j = 0; j < content.options.development_images.length; j++) {
            if(merge.merge_parent === content.options.development_images[j].project_id) {
                mergeParent = content.options.development_images[j];
            }
        }

        if(mergeParent) {
            for(var j = 0; j < content.options.projects_select.length; j++) {
                if(mergeParent.project_id === content.options.projects_select[j].value) {
                    mergeName = content.options.projects_select[j].label;
                }
            }
    
            //now lets change some things
            for(var j = 0; j < content.options.incentives.length; j++) {
                if(merge.merge_children.includes(content.options.incentives[j].project.id.toString())) {
                    //now just make the data match the way it should
                    content.options.incentives[j].project.id = parseInt(mergeParent.project_id);
                    content.options.incentives[j].project.name = mergeName;
                }
            }
    
            for(var j = 0; j < content.options.development_images.length; j++) {
                if(merge.merge_children.includes(content.options.development_images[j].project_id.toString())) {
                    mergeParent = content.options.development_images[j];
                    developmentRemove = j;
                }
            }
    
            for(var j = 0; j < content.options.projects_select.length; j++) {
                if(merge.merge_children.includes(content.options.projects_select[j].value)) {
                    mergeName = content.options.projects_select[j].label;
                    selectRemove = j;
                }
            }
    
            content.options.development_images.splice(developmentRemove, 1);
            content.options.projects_select.splice(selectRemove, 1);
        }
    }

    return {
        type: CONTENT_UPDATE,
        content
    }
}

export function queryContent() {
    return function(dispatch) {
        dispatch(content_notify);
        return apiService.getContent()
        .then(response => {
            if(response.data.success === true)
            dispatch(updateContent(response.data.data));
        }).catch(err => {
            console.error(err);
        });
    }
}