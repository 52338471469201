import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LeftSidebar from '../LeftSidebar/LeftSidebar';
import './CreateAccount.scss';
import LeftUserRegisterSidebar from '../LeftUserRegisterSidebar/LeftUserRegisterSidebar';
import UserRegisterForm from './UserRegisterForm';
import FormReqStar from '../Form/FormReqStar';

function CreateAccount() {
    var history = useHistory();
    var user = useSelector(state => state.user);
    var pageContent = useSelector(
        state => state.content.pages.create_account
    );

    if(user.userLoggedIn === true) {
        if(user.userVerified === false) {
            history.push('/account-created');
        }
    }
    
    return (
        <div className="CreateAccount">
            <LeftSidebar />
            <LeftUserRegisterSidebar />
            <div className="create-account-content-container">
                {pageContent?.form && 
                    <UserRegisterForm form={pageContent.form} />
                }
                <div className="required-fields">
                    <FormReqStar nospace />&nbsp;Required
                </div>
            </div>
        </div>
    );
}

export default CreateAccount;
