import "./MobileHamburger.scss";
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

function MobileHamburgerItem(props) {
    var history = useHistory();

    function handleClick() {
        props.close(false);
        history.push(props.to);
    }

    return(
        <div className="MobileHamburgerItem" onClick={handleClick}>
            {props.children}
        </div>
    );
}

function MobileHamburger(props) {
    //var [open, setOpen] = useState(false);
    var classes = "MobileHamburger";

    if(props.open) classes += " open";

    function toggleOpen() {
        props.setOpen(!props.open);
    }

    return(
        <div className={classes}>
            <div className="hamburger-button-container" onClick={toggleOpen}>
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
            </div>
            <div className="hamburger-content-container">
                <MobileHamburgerItem to="/builder-story" close={props.setOpen}>
                    Builder Story
                </MobileHamburgerItem>
                <MobileHamburgerItem to="/tier-details" close={props.setOpen}>
                    Tier Details
                </MobileHamburgerItem>
                <MobileHamburgerItem to="/faq" close={props.setOpen}>
                    FAQ
                </MobileHamburgerItem>
                <MobileHamburgerItem to="/privacy-policy" close={props.setOpen}>
                    Privacy Policy
                </MobileHamburgerItem>
                <MobileHamburgerItem to="/terms-and-conditions" close={props.setOpen}>
                    Terms &amp; Conditions
                </MobileHamburgerItem>
            </div>
        </div>
    )
}

export default MobileHamburger;