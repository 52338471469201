import "./ProjectDetails.scss";
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { EmailIcon, ArrowIcon, MapIcon, GreyPlusIcon } from './ProjectDetailsIcons';
import LeftSidebar from '../LeftSidebar/LeftSidebar';
import ContentLightBlueWrapper from '../ContentLightBlueWrapper/ContentLightBlueWrapper';
import ProjectBackArrow from "./ProjectBackArrow";
import { useLocation } from "react-router";
import reactDom from "react-dom";

function ProjectDetails(props) {
    var developments = useSelector(state => state.content.options.development_images);
    var projectsNames = useSelector(state => state.content.options.projects_select);
    var manualProjects = useSelector(state => state.content.options.manual_projects);

    var development = null;
    var location = useLocation();

    var incentives = useSelector(state => state.content.options.incentives);
    var [display, setDisplay] = useState(false);

    var [width, setWidth] = useState(window.innerWidth);

    function updateSize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', updateSize);

        return () => {
            window.removeEventListener('resize', updateSize);
        }
    })

    var ref = useRef();

    function ProjectDetailsButton(props) {
        return(
            <a href={props.link} className="ProjectDetailsButton" target="_blank">
                {props.icon}
                <span>{props.text}</span>
            </a>
        )
    }

    function ProjectDetailsIncentive(props) {
        return(
            <div className="ProjectDetailsIncentive">
                <span className="text">
                    {props.text}
                </span>
                <span className="points">
                    {props.points}
                </span>
            </div>
        )
    }

    function loadMoreHandle() {
        setDisplay(!display);
    }

    var incets = [];
    var proj;
    var pName = location.pathname.split('/');
    pName = pName[pName.length - 1];

    if(developments && projectsNames) {
        for(var i = 0; i < developments.length; i++) {
            for(var j = 0; j < projectsNames.length; j++) {
                if(developments[i].project_id == projectsNames[j].value) {

                    if(pName === projectsNames[j].label.replaceAll(' ', '-').toLowerCase()) {
                        proj = developments[i];
                        proj.projectName = projectsNames[j].label;

                        for(var k = 0; k < incentives.length; k++) {
                            if(incentives[k].project.id == proj.project_id) {
                                for(var l = 0; l < incentives[k].units.length; l++) {
                                    var inc = {};
                                    inc.text = inc.text = `${incentives[k].units[l].floorplan} 
                                        ${incentives[k].units[l].unit_number} ${incentives[k].project.name}`;
                                    inc.points = incentives[k].points;

                                    incets.push(inc);
                                }

                                proj.incentiveCount = incets.length;
                                if(display)
                                    proj.incentives = incets;
                                else 
                                    proj.incentives = incets.slice(0, 4);
                            }
                        }
                        
                        development = proj;
                    }
                }
            }
        }

        if(!development && manualProjects) {
            //we need to check the manual projects now to see if any of them match the name in the url
            for(var i = 0; i < manualProjects.length; i++) {
                if(manualProjects[i].project_name.replaceAll(" ", "-").toLowerCase() === pName) {
                    proj = {};
                    proj.projectName = manualProjects[i].project_name;
                    proj.project_image = manualProjects[i].project_image;
                    proj.description = manualProjects[i].description;
                    proj.project_icon = manualProjects[i].project_icon;
                    proj.gross_floor_area = manualProjects[i].gross_floor_area;
                    proj.homes = manualProjects[i].homes;
                    proj.address = manualProjects[i].address;
                    proj.inquire_address = manualProjects[i].inquire_address;
                    proj.broker_portal_link = manualProjects[i].broker_portal_link;

                    for(var l = 0; l < manualProjects[i].incentives.length; l++) {
                        var incentive = manualProjects[i].incentives[l];

                        for(var k = 0; k < incentive.units.length; k++) {
                            var inc = {};
                            
                            inc.text = `${incentive.units[k].floorplan} 
                                ${incentive.units[k].unit_number} ${proj.projectName}`;

                            incets.push(inc);
                        }

                        proj.incentiveCount = incets.length;
                        if(display)
                            proj.incentives = incets;
                        else
                            proj.incentives = incets.slice(0, 4);
                    }
                }
            }

            development = proj;
        }
    }

    var loadMoreClasses = "load-more";
    if(typeof development.incentives === 'undefined' || development.incentiveCount <= 4) loadMoreClasses += " disabled";

    return(
        <div className="ProjectDetails">
            <LeftSidebar />
            <ContentLightBlueWrapper>
                <div className="ProjectDetailsContentContainer">
                    {width < 900 &&
                        <div className="nav-wrapper">
                            <ProjectBackArrow to="/portfolio" text="Back to Portfolio" />
                        </div>
                    }
                    <div className="content-wrapper">
                        {width > 899 &&
                            <div className="nav-wrapper">
                                <ProjectBackArrow to="/portfolio" text="Back to Portfolio" />
                            </div>
                        }
                        <div className="inner-wrapper">
                            <img
                                src={development?.project_image.url}
                                alt={development?.project_image.alt}
                                className="project-image"
                            />
                            <div className="description-container"
                                dangerouslySetInnerHTML={{__html: development?.description}}
                            />
                        </div>
                    </div>
                    <div className="project-info-wrapper">
                        <div className="project-info-container">
                            <img
                                src={development?.project_icon?.url}
                                alt={development?.project_icon?.alt}
                                className="project-logo"
                            />
                            <div className="info-group">
                                <div className="field">
                                    <label>
                                        Gross Floor Area
                                    </label>
                                    <span>
                                        {development?.gross_floor_area}
                                    </span>
                                </div>
                                <div className="field">
                                    <label>
                                        Homes
                                    </label>
                                    <span>
                                        {development?.homes}
                                    </span>
                                </div>
                                <div className="field">
                                    <label>
                                        Address
                                    </label>
                                    <a href={development?.address.link} target="_blank">
                                        {development?.address?.text}
                                        <MapIcon />
                                    </a>
                                </div>
                            </div>
                            <div className="link-groups">
                                <ProjectDetailsButton
                                    link={development?.inquire_address}
                                    icon={<EmailIcon />}
                                    text="Inquire"
                                />
                                <ProjectDetailsButton
                                    link={development?.broker_portal_link}
                                    icon={<ArrowIcon />}
                                    text="Website"
                                />
                            </div>
                        </div>
                        {width > 899 &&
                            <div className="incentives-container">
                                <div className="title">
                                    Incentives
                                </div>
                                <div className="item-container" ref={ref}>
                                    {typeof development !== 'undefined' && typeof development.incentives !== 'undefined' && development?.incentives.map((incentive, key) =>
                                        <ProjectDetailsIncentive
                                            key={key}
                                            text={incentive.text}
                                            points={incentive.points}
                                        />
                                    )}
                                </div>
                                <div className={loadMoreClasses} onClick={loadMoreHandle}>
                                    Load More
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </ContentLightBlueWrapper>
        </div>
    )
}

export default ProjectDetails;