import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../Form/Form';
import { userUpdateValues } from '../../redux/actions/userActions';

function ProfileDetailsForm(props) {
    var dispatch = useDispatch();
    var user = useSelector(state => state.user.user);
    var authToken = useSelector(state => state.user.authToken);
    var canSubmit = useSelector(state => state.user.userProfileDetailsCanUpdate);

    var formObj = {
        fname: user.firstname,
        lname: user.lastname,
        email: user.email,
        phone: user.phone,
        brokerage: user.brokerage,
        brokerage_address: user.brokerage_street_address,
        brokerage_unit: user.unit_number,
        brokerage_postal: user.postal_code,
        brokerage_city: user.city
    };

    function updateProfileDetails(values) {
        //dispatch(updateUserProfileInfo(values)); 
        //TODO: Need to add login to to actually update the
        //user profile as well as to prepopulate the fields   
        
        dispatch(userUpdateValues(authToken, values));
    }

    return(
        <Form
            fields={props.form}
            preValues={formObj}
            classes="ProfileDetailsForm"
            onSubmit={updateProfileDetails}
            submitting={!canSubmit}
        />
    )
}

export default ProfileDetailsForm;