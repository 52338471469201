import "./FAQQuestion.scss";
import React from 'react';
import { useRef } from "react";

function FAQQuestion(props) {
    var height = 0;
    var padding = 0;
    var ref = useRef();

    var classes = "FAQQuestion";

    if(props.open) {
        if(ref.current) {
            height = (ref.current.scrollHeight + 48) + 'px';
            padding = '24px'
        }
        else {
            height = '0px';
        }

        classes += " open";
    }

    var questionStyles = {
        height: height,
        paddingTop: padding,
        paddingBottom: padding
    };

    function clickCallback() {
        if(props.callback)
        props.callback(props.id);
    }

    return(
        <div className={classes} onClick={clickCallback}>
            <div className="FATitle">
                <span>
                    {props.question}
                </span>
                <div className="plus-container">
                    <span />
                    <span />
                </div>
            </div>
            <div className="FAQuestion" style={questionStyles} ref={ref} onClick={(e)=> e.stopPropagation()}
                dangerouslySetInnerHTML={{__html: props.answer}}
            />
        </div>
    );
}

export default FAQQuestion;