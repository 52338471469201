export function addCommas(number) {
    if(number == -1) {
        return "∞";
    }
    
    var num = number.toString();
    var output = "";

    var j = 0;
    for(var i = num.length - 1; i >= 0; i--) {
        if(j++ === 3) {
            output = "," + output;
            j = 1;
        }

        output = num.charAt(i) + output;
    }
    
    return output;
}