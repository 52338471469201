import "./LeftUserSidebarLink.scss";
import React from 'react';
import { Link } from 'react-router-dom';

function LeftUserSidebarLink(props) {
    var classes = "LeftUserSidebarLink";
    if(props.active) classes += " active";

    return(
        <Link to={props.to} className={classes}>
            <div className="image-container">
                <img src={props.icon} alt="" /> 
            </div>
            <div className="info-container">
                <label className="title">
                    {props.title}
                </label>
                <span className="description">
                    {props.description}
                </span>
            </div>
            <div className="arrow-container">
                <img src="/img/profile-arrow-right.svg" alt="" />
            </div>
        </Link>
    );
}

export default LeftUserSidebarLink;