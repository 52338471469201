import "./ArrowLink.scss";
import React from 'react';
import { Link } from 'react-router-dom';

function ArrowLink(props) {
    var classes = "ArrowLink";
    if(props.black) classes += " black";

    if(props.external) {
        return (
            <a href={props.to} className={classes} target="_blank">
                <img src="/img/right-link-arrow.svg" alt="" /> {props.label}
            </a>
        )
    }
    else {
        return (
            <Link to={props.to} className={classes}>
                <img src="/img/right-link-arrow.svg" alt="" /> {props.label}
            </Link>
        );
    }
}

export default ArrowLink;