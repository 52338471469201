import React from 'react';
import './DashboardEarnings.scss';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ArrowLink from '../ArrowLink/ArrowLink';
import { addCommas } from '../Utilities/utilities';

function DashboardEarnings() {
    var user = useSelector(state => state.user.user);

    var earningData = [
        {
            icon: "/img/firm-sales-icon.svg",
            number: user?.sales?.firm,
            label: "Firm Sale(s)"
        },
        {
            icon: "/img/conditional-sales-icon.svg",
            number: user?.sales?.conditional,
            label: "Conditional Sale(s)"
        },
        {
            icon: "/img/rewards-icon.svg",
            number: user?.sales?.incentives,
            label: "Incentive(s)"
        },
    ];

    function addCommas(number) {
        var num =  number.toString();
        
        var count = 0;
        for(var i = num.length; i > 0; i--) {

            if(count === 3) {
                //add a comma
                count = 0;
                num = num.substring(0, i) + "," + num.substring(i, num.length);
                i--;
            }

            count++;
        }

        return num;
    }

    function getPoints(points) {
        if (points < 0) {
            return addCommas(points);
        } else if (points == 0) {
            return '-';
        } else {
            return '+' + addCommas(points);
        }
    }

    function EarningsIcon(props) {
        return (
            <React.Fragment>
                <img src={props.icon} className="icon" alt="icon" />
                
                <label className="label">
                    <span className="number">
                        {props.number}
                    </span>
                    {props.label}
                </label>
            </React.Fragment>
        );
    }

    function PointsEventRow(props) {
        return (
            <React.Fragment>
                <span className="date">
                    {props.date}
                </span>
                <label className="project-name">
                    {props.status}
                </label>
                <span className="points">
                    {getPoints(props.points)}
                </span>
            </React.Fragment>
        );
    }

    return (
        <div className="DashboardEarnings">
            <div className="earnings-container">
                <span className="earnings">
                    My Earnings
                </span>
                <span className="summery">
                    <ArrowLink to="/dashboard/summary" label="View Summary" />
                </span>
            </div>
            <div className="sales-rewards">
                {earningData.map((earning, key) => 
                    <EarningsIcon
                        key={key}
                        icon={earning.icon}
                        number={earning.number}
                        label={earning.label}
                    />
                )}
                <img src="/img/bottom-pointer-bg.svg" className="bg-arrow"/>
            </div>
            <div className="points-events">
                {user?.sales?.mini_summary.map((point, key) => 
                    <PointsEventRow
                        key={key}
                        date={point.date}
                        project={point.project}
                        status={point.status}
                        points={point.points}
                    />
                )}
            </div>
        </div>
    );
}

export default DashboardEarnings;
