import React from 'react';
import './LoginRight.scss';
import LoginBottomRight from '../LoginBottomRight/LoginBottomRight';
import LoginForm from '../LoginForm/LoginForm';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MarlinSpringLogo from '../MarlinSpringLogo/MarlinSpringLogo';


function LoginRight() {
    var loggedIn = useSelector((state) => state.user.userLoggedIn);
    var content = useSelector((state) => state.content.pages.home);

    var history = useHistory();
    
    if(loggedIn === true) history.push('dashboard');

    return (
        <div className="LoginRight">
            <div className="right-content-wrapper">
                <MarlinSpringLogo blue classes="ascent-logo" />
                <span className="tagline">Ascent<br />Program</span>
                {content && 
                    <LoginForm
                        form={content.form}
                    />
                }
                <div className="login-links">
                    <Link to="/forgot-password">Forgot Password?</Link>
                </div>
                <LoginBottomRight />
            </div>
        </div>
    );
}

export default LoginRight;
