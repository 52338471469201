import React, { useEffect, useState } from 'react';
import LeftSidebar from '../LeftSidebar/LeftSidebar';
import DashboardNotificationBar from '../DashboardNotificationBar/DashboardNotificationBar';
import DashboardSummary from '../DashboardSummary/DashboardSummary';
import DashboardAnnouncement from '../DashboardAnnouncement/DashboardAnnouncement';
import DashboardRank from '../DashboardRank/DashboardRank';
import DashboardEarnings from '../DashboardEarnings/DashboardEarnings';
import DashboardSidebarRight from '../DashboardSidebarRight/DashboardSidebarRight';

import './Dashboard.scss';

function Dashboard() {
    var [isBarHidden, setBarHidden] = useState(false);
    var classes = "whats-new-button";
    if(isBarHidden) classes += " whats-new-button";
    return (
        <div className="Dashboard">
            <LeftSidebar />
            <div className="outer-wrapper">
                <DashboardNotificationBar
                    isBarHidden={isBarHidden}
                    setBarHidden={setBarHidden}
                />
                <div className={classes} >
                    What's New
                </div>
                <div className="main-content-wrapper">
                    <DashboardSummary />
                    <DashboardAnnouncement />
                    <DashboardRank />
                    <DashboardEarnings />
                </div>
            </div>
            <DashboardSidebarRight />
        </div>
    );
}

export default Dashboard;
