import React from 'react';
import { Link } from 'react-router-dom';
import './FooterLinks.scss';

function FooterLinks(props) {
    return (
        <div className="FooterLinks">
        <div className="footer-pp">
            <Link to="/privacy-policy">Privacy Policy</Link>
            <br />
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
        </div>
        <div>
            <p>&copy; Marlin Spring, 2021. All Rights Reserved.</p>
        </div>
    </div>
    );
}

export default FooterLinks;
