import React, { useState } from 'react';
import Form from '../Form/Form';
import { useDispatch } from 'react-redux';
import apiService from '../../services/apiService';
import { addPopupMessage } from '../../redux/actions/popupActions';

function ForgotPasswordForm(props) {
    var dispatch = useDispatch();
    var [submitting, setSubmitting] = useState(false);

    function emailForgotPasswordVerification(values) {
        setSubmitting(true);
        //send the api here so we can use history
        apiService.post('request-password-reset', values)
        .then((response) => {
            var message;
            
            if(response.data.success === true) {
                message = {
                    type: 'success',
                    title: 'Success',
                    message: "Please check your inbox to reset password."
                }
            } else {
                message = {
                    type: 'error',
                    title: 'Error',
                    message: "Email address not found. Please try again."
                }
            }

            dispatch(addPopupMessage(message));
            setSubmitting(false);
        });
    }

    return (
        <Form
            fields={props.form}
            classes="ForgotPasswordForm"
            onSubmit={emailForgotPasswordVerification}
            submitting={submitting}
        />
    )
}

export default ForgotPasswordForm;