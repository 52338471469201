import "./FormFileField.scss";
import React, { useRef, useState } from "react";
import FormReqStar from "./FormReqStar";

function FormFileField(props) {
    var classes = "FormFileField";
    var errorClasses = "form-error-label";
    var inputRef = useRef();
    var [preview, setPreview] = useState("");

    if(props.classes)
    classes = classes + " " + props.classes;

    if(props.icon)
    classes = classes + " has-icon";

    if(props.error)
    errorClasses += "has-error";

    function changed() {
        setPreview(URL.createObjectURL(inputRef.current.files[0]));
    }

    return (
        <div className={classes}>
            <input
                ref={inputRef}
                type="file"
                onChange={changed}
                accept=""
            />
        </div>
    )
}

export default FormFileField;