import './LeftNotificationSidebar.scss';
import React, { useState, useReducer, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userSetNotificationReadStatus } from '../../redux/actions/userActions';
import { userLogout } from '../../redux/actions/userActions';
import AnimateInbox from "./AnimateInbox";

function LeftNotificationSidebar(props) {
	var dispatch = useDispatch();
    const mainDivRef = React.useRef();

    const [isActive, setActive] = useState(0);
    const [state, setState] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {tempindex: 0}
    //  state.tempindex = -1;
    )
	const index = props.index;
	const setIndex = props.setIndex;
	const time_ago = props.time_ago;
    var scrollPosition = 0;

    var user = useSelector(state => state.user.user);

    if (isActive === 0) {
    	setActive("");
    }

    var rewardTypes = [
        {
            type: "",
            label: "All"
        },
        {
            type: "points",
            label: "Points"
        }
    ];

    function updateIndex(i, is_read) {
        dispatch(userSetNotificationReadStatus(i, 'y'));

        for (var j=0; j < user.notifications.length; j++) {
            if (user.notifications[j].id == index) {
               user.notifications[j].is_read = 'y';
            }
        }

        setIndex(i);
        props.setMobileActive(true);
    }

    function notification_left_box_classes(i, is_read) {
        let classes = 'notification-left-box';
        if (i === index) classes += ' active';

        return classes;
    }

    function getIcon(subType) {
        if (typeof (subType) != 'undefined' && subType != '' && typeof (subType) != 'null') {
            return (
                <img src={'/img/notification-icons/' + subType + '_icon.svg'} className="notification-icon" />
            )
        } else {
            return (
                <img src={'/img/notification-icons/default_icon.svg'} className="notification-icon" />
            )
        }
    }

    function show_notifications(is_read, type) {
    	return (
			user?.notifications?.filter(notification => notification.is_read == is_read && (notification.type == isActive || isActive === "")).map((notification, key) => (
                <div
                    key={notification.id}
                    className={notification_left_box_classes(notification.id, is_read)}
                    onClick={() => updateIndex(notification.id, is_read)}
                    ref={createRef()}
                >
                    <div>
                        {getIcon(notification.subtype)}
                        <h3>{notification.title}</h3>
                        <p className="date">{time_ago(notification.created_at)}</p>
                    </div>

                    {rightArrow()}
                </div>
            ))
    	)
    }

    function rightArrow() {
    	return (
			<svg width="8px" height="12px" viewBox="0 0 8 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="4.-Notifications" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="4a.-Notiifications-[Tansy-A-Example]" transform="translate(-516.000000, -236.000000)" fill="#1C2E3B" fillRule="nonzero">
                        <g id="Content" transform="translate(204.000000, -0.000000)">
                            <g id="LHS-Settings" transform="translate(0.000000, 0.000000)">
                                <g id="Notifications" transform="translate(32.000000, 188.000000)">
                                    <g id="Notification" transform="translate(0.000000, 28.000000)">
                                        <path d="M281.701539,31.835945 L287.223437,26.398458 C287.443713,26.1781369 287.443713,25.821906 287.228106,25.6015849 L281.706254,20.1640979 C281.485933,19.9437767 281.129702,19.9437767 280.909381,20.1640979 L280.576541,20.4968913 C280.356266,20.7172124 280.356266,21.073489 280.576541,21.2937644 L285.367164,26.0000215 L280.571872,30.7062785 C280.351551,30.9265539 280.351551,31.2828305 280.571872,31.5031516 L280.904666,31.835945 C281.124987,32.0562662 281.481263,32.0562662 281.701539,31.835945 Z" id="angle-right"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
    	)
    }


    function filter_types_classes(type) {
    	var classes = "";
        if (isActive === type) {
        	classes='active';
        }

        return classes;
    }

    function filter_heading_classes(is_read) {
    	var classes = "";
        if (getMessageCount(is_read) <= 0) {
        	classes='zero-items';
        }

        return classes;
    }

    function updateFilterType(type) {
    	setActive(type);
    }

    function getMessageCount(is_read) {
    	return user?.notifications?.filter(notification => notification.is_read == is_read && (notification.type == isActive || isActive === "")).length;
    }

    function onScroll(e) {
       //console.log(mainDivRef.current.scrollTop);
       scrollPosition = e.target.scrollTop;
    }

    return (
        <div className="LeftNotificationSidebar" id="LeftNotificationSidebar" onScroll={onScroll} ref={mainDivRef}>
            <div className="links">
                <h2>Notifications</h2>
                <div className="filter-notifications-type">
	                {rewardTypes.map((rewardType, key) => 
	                    <span 
		                	key={rewardType.key}
			                datatype={rewardType.type}
			                className={filter_types_classes(rewardType.type)} 
			                onClick={() => updateFilterType(rewardType.type)}
		                >
		                	{rewardType.label}
		                </span>
	                )}
                </div>

                <AnimateInbox mainDivRef={mainDivRef}>
                    <h4 className={filter_heading_classes('n')} key="unread-title" ref={createRef()}>New <span>{getMessageCount('n')}</span></h4>
                    {show_notifications('n', 'points')} 
                    <h4 className={filter_heading_classes('y')} key="read-title" ref={createRef()}>Archive</h4>
    				{show_notifications('y', 'points')}
                </AnimateInbox>
            </div>
        </div>
    );
}

export default LeftNotificationSidebar;