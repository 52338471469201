import React, { useState, useLayoutEffect, useEffect } from "react";
import usePrevious from "./usePrevious";
import calculateBoundingBoxes from "./calculateBoundingBoxes";

function AnimateInbox(props) {
  const [boundingBox, setBoundingBox] = useState({});
  const [prevBoundingBox, setPrevBoundingBox] = useState({});
  const prevChildren = usePrevious(props.children);

  var scrollPosition = 0;
  if (props.mainDivRef.current?.scrollTop) {
  	 scrollPosition = props.mainDivRef.current?.scrollTop;
  }

  useLayoutEffect(() => {
    const newBoundingBox = calculateBoundingBoxes(props.children, scrollPosition);
    setBoundingBox(newBoundingBox);
  }, [props.children]);

  useLayoutEffect(() => {
    const prevBoundingBox = calculateBoundingBoxes(prevChildren, scrollPosition);
    setPrevBoundingBox(prevBoundingBox);
  }, [prevChildren]);

  useEffect(() => {
    const hasPrevBoundingBox = Object.keys(prevBoundingBox).length;

    if (hasPrevBoundingBox) {
      React.Children.forEach(props.children, child => {
        if (child) {
	        const domNode = child.ref.current;

	        if (child.key != 'unread-title' && child.key != 'read-title') {
				const firstBox = prevBoundingBox[child.key];
				const lastBox = boundingBox[child.key];
				if (firstBox != undefined && lastBox != undefined) {
					//console.log(firstBox.top + "--" + lastBox.top + "--" + firstBox.scrollTop);
					const changeInY = firstBox.top - lastBox.top;

					//console.log(changeInY);
					if (changeInY) {
					  requestAnimationFrame(() => {
					    // Before the DOM paints, invert child to old position
					    domNode.style.transform = `translateY(${changeInY}px)`;
					    domNode.style.transition = "transform 0s cubic-bezier(0.72, 0, 0.32, 1)";
					    requestAnimationFrame(() => {
					      // After the previous frame, remove
					      // the transistion to play the animation
					      domNode.style.transform = "";
					      domNode.style.transition = "transform 800ms cubic-bezier(0.72, 0, 0.32, 1)";
					    });
					  });
					}
				} else {
				  requestAnimationFrame(() => {
				    // Before the DOM paints, invert child to old position
				    domNode.style.opacity = `0`;
				    domNode.style.transition = "opacity 0s cubic-bezier(0.72, 0, 0.32, 1)";
				    requestAnimationFrame(() => {
				      // After the previous frame, remove
				      // the transistion to play the animation
				      domNode.style.opacity = "1";
				      domNode.style.transition = "opacity 800ms cubic-bezier(0.72, 0, 0.32, 1)";
				    });
				  });
				}
			}
	    }
      });
    }
  }, [boundingBox, prevBoundingBox, props.children]);

  return props.children;
};

export default AnimateInbox;
