import "./IncentiveFilter.scss";
import React from 'react';

function IncentiveFilter(props) {

    function handleUpdate(e) {
        if(props.update)
        props.update(e.target.value);
    }

    return (
        <div className="IncentiveFilter">
            <div className="inner-wrapper">
                <select name="" id="" onChange={handleUpdate} ref={props.container}>
                    <option value="">{props.label}</option>
                    {props.options && props.options.map((option, key) => 
                        <option value={option.value} disabled={option.disabled} key={key}>{option.label}</option>
                    )}
                </select>
                <img src="/img/angle-down.svg" alt="" className="dropdown-arrow" />
            </div>
        </div>
    );
}

export default IncentiveFilter;