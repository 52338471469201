import React from 'react';
import { useSelector } from 'react-redux';
import Row from '../Row/Row';
import LeftSidebar from '../LeftSidebar/LeftSidebar';
import ContentLightBlueWrapper from '../ContentLightBlueWrapper/ContentLightBlueWrapper';
import './TermsConditions.scss';

function TermsConditions() {
	const content = useSelector((state) => state.content.pages?.['terms_&_conditions']);
    
    return (
        <div className="TermsConditions">
            <LeftSidebar />
            <ContentLightBlueWrapper>					
                <div className="right-side">
                    <h1>{content?.post_title}</h1>
                    <Row>
                        <div dangerouslySetInnerHTML={{__html: content?.content}} />
                    </Row>
                </div>
            </ContentLightBlueWrapper>
        </div>
    );
}

export default TermsConditions;
