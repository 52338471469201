import './LeftSideBarPrivacyNav.scss';
import React from 'react';
import { Link } from 'react-router-dom';

function LeftSideBarPrivacyNav(props) {
    var styles = {};
    var classes = "LeftSideBarPrivacyNav"

    if(props.blue) {
        //styles.color = "#1C2E3B";
        //styles.paddingLeft = "0";
        //styles.paddingRight = "0";
        classes += " blue";
    }

    function isActive(link) {
        if(!props.location) return "";
        return props.location.pathname === link ? "active" : "";
    }

    return( 
        <div className={classes}>
            <Link to="/privacy-policy" className={isActive('/privacy-policy')}>Privacy Policy</Link><br/>
            <Link to="/terms-and-conditions" className={isActive('/terms-and-conditions')}>Terms & Conditions</Link><br/>
        </div>
    )
}

export default LeftSideBarPrivacyNav;