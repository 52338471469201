import "./PopupMessages.scss";
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { nextPopupMessage } from '../../redux/actions/popupActions';

function PopupMessages() {
    var dispatch = useDispatch();
    var item = useSelector(state => state.popup.current);
    var open = useSelector(state => state.popup.open);
    var length = useSelector(state => state.popup.queueLength);

    var rootClasses = "PopupMessages";
    if(open) rootClasses += " open";

    if(length > 1) {
        rootClasses += " two-stack";
    }
    else if(length > 0) {
        rootClasses += " one-stack";
    }

    if(item?.type === "success") rootClasses += " success";
    if(item?.type === "error") rootClasses += " error";

    useEffect(() => {
        if(item !== null) {
            var interval = setInterval(() => {
                dispatch(nextPopupMessage());
            }, 4000);
    
            return () => {
                clearInterval(interval);
            }
        }
    }, [item]);

    return (
        <div className={rootClasses}>
            <div className="popup-wrapper">
                <div className="icon-container">
                    <img src="/img/notification-icon.svg" alt="" className="icon"/>
                </div>
                <div className="content-container">
                    <div className="title">{item?.title}</div>
                    <div className="copy">{item?.message}</div>
                </div>
            </div>
            <div className="stack-1"></div>
            <div className="stack-2"></div>
        </div>
    )
}

export default PopupMessages;