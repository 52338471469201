import React, { useEffect, useState } from 'react';
import './App.scss';
import Router from './components/Router/Router';
import { useDispatch } from 'react-redux';
import { queryContent } from './redux/actions/contentActions';
import cookieService from './services/cookieService';
import { checkUserLoggedIn, updateUserContent, userVerifyFailed } from './redux/actions/userActions';
import { useSelector } from 'react-redux';
import LoadingCover from './components/LoadingCover/LoadingCover';

function App() {
	const dispatch = useDispatch();
    var user = cookieService.getCookie('asc_user');
    var contentLoaded = useSelector(state => state.content.contentLoaded);
    var userObj = useSelector(state => state.user);

    var loggedIn = useSelector(state => state.user.userLoggedIn);
    var verified = useSelector(state => state.user.userVerified);
    var confirm = useSelector(state => state.user.userConfirmed);
    var preverified = useSelector(state => state.user.userPreVerified);

    function IsUserLoggedIn() {
        return !(!loggedIn && !verified && !confirm && !preverified);
    }

    var loading = true;

    if(userObj && userObj.userLoginProcessComplete && contentLoaded)
    loading = false;

    if(user)
        user = JSON.parse(user);

	//this one only fires once use it to init stuff
    useEffect(() => {
        if(user) dispatch(checkUserLoggedIn(user));
        else dispatch(userVerifyFailed());
		dispatch(queryContent());
	}, []);

    /*
    useEffect(() => {
        setTimeout(contentUpdate, 300000);
    })
    */

    function contentUpdate() {
        if(IsUserLoggedIn()) {
            dispatch(queryContent());
            dispatch(updateUserContent());
        }   
    }

    if(!loading)
    return <Router />;
    else
    return <LoadingCover />;
}

export default App;