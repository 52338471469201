import React from 'react';
import './ContentLightBlueWrapper.scss';

function ContentLightBlueWrapper(props) {
    return (
        <div className="ContentLightBlueWrapper">
            {props.children}
        </div>
    );
}

export default ContentLightBlueWrapper;
