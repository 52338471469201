import "./Profile.scss";
import React from 'react';
import LeftSidebar from '../LeftSidebar/LeftSidebar';
import ProfileDetails from "../ProfileDetails/ProfileDetails";
import ProfileSecurity from '../ProfileSecurity/ProfileSecurity';
import ProfilePreferences from '../ProfilePreferences/ProfilePreferences';
import LeftUserSidebar from '../LeftUserSidebar/LeftUserSidebar';
import { useLocation } from "react-router-dom";

function Profile(props) {
    var location = useLocation();

    function addSelectedContent() {
        switch(location.pathname) {
            case '/profile/details':
                return <ProfileDetails />
            case '/profile/security':
                return <ProfileSecurity />
            case '/profile/preferences':
                return <ProfilePreferences />
            default:
                return <div></div>
        }
    }

    return(
        <div className="Profile">
            <LeftSidebar />
            <LeftUserSidebar page={props.page} />
            {location.pathname !== "/profile" &&
                <div className="profile-content-container">
                    {addSelectedContent()}
                </div>
            }
        </div>
    );
}

export default Profile;