import "./FormTextField.scss";
import React from 'react';
import FormReqStar from './FormReqStar';
import { useEffect } from "react";

function FormTextField(props) {
    var classes = "FormTextField FormField";
    var errorClasses = "form-error-label";

    if(props.classes)
    classes = classes + " " + props.classes;

    if(props.icon)
    classes = classes + " has-icon";

    if(props.error)
    errorClasses += " has-error";

    function InputChanged(e) {
        var value = e.target.value;
        props.setFormValue({...props.formValue, [props.name]: value});
    }

    function clearError() {
        var error = Object.assign({}, props.errorValue);
        error[props.name] = false;

        props.setErrorValue(error);
    } 

    return (
        <div className={classes}>
            {props.label &&
                <label htmlFor={props.name}>
                    {props.label}
                    {props.required && <FormReqStar/>}
                </label>
            }
            {props.icon && 
                <img src={props.icon} />
            }
            <span className={errorClasses}>
                {props.errorText}
            </span>
            <input
                className="input"
                type={props.type}
                name={props.name}
                id={props.name}
                value={props.formValue[props.name]}
                onChange={InputChanged}
                onFocus={clearError}
                placeholder={props.placeholder ? props.placeholder : ""}
            />
        </div>
    )
}

export default FormTextField