import React from 'react';
import { useSelector } from 'react-redux';
import Row from '../Row/Row';
import Col from '../Col/Col';
import LeftSidebar from '../LeftSidebar/LeftSidebar';
import ContentLightBlueWrapper from '../ContentLightBlueWrapper/ContentLightBlueWrapper';
import './BuilderStory.scss';

function BuilderStory() {
	const content = useSelector((state) => state.content.pages?.builder_story);

    return (
        <div className="BuilderStory">
            <LeftSidebar />
            <ContentLightBlueWrapper>
                <h1>{content?.post_title}</h1>

                <Row>
                    <Col className="xxsmall-12">
                        <img className="logo" src={content?.logo?.url} alt="" />
                    </Col>
                    <Col className="xxsmall-12 large-6">
                        <p dangerouslySetInnerHTML={{__html: content?.copy}}></p>
                    </Col>
                    <Col className="xxsmall-12 large-6">
                        <video src={content?.right_video} playsInline autoPlay muted loop />
                    </Col>
                </Row>
            </ContentLightBlueWrapper>
        </div>
    );
}

export default BuilderStory;
