import './LeftSideBarIcon.scss';
import React from 'react';
import { Link } from 'react-router-dom';

function LeftSideBarIcon(props) {
    var classes = "LeftSideBarIcon";
    if(props.active) classes += " active";
    if(props.mobile) classes += " mobile";

    function handleClick() {
        if(props.setOpen) {
            props.setOpen(false);
        }
    }

    return(
        <Link className={classes} to={props.to} onClick={handleClick}>
            {props.img}
            {props.children}
        </Link>
    );
}

export default LeftSideBarIcon;