import "./WhatsNew.scss";
import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router";
import Incentive from "../Incentive/Incentive";
import UpcomingProject from "../UpcomingProject/UpcomingProject";

function WhatsNew(props) {
    var incentives = useSelector(state => state.content.options.incentives);
    var whatsNew = useSelector(state => state.content.options.upcoming_projects);
    var history = useHistory();
    var sortedIncentives = [];
    var [width, setWidth] = useState(window.innerWidth);

    if(typeof incentives !== 'undefined') {
        incentives.sort((a, b) => {
            var aDate = new Date(a.start_date);
            var bDate = new Date(b.start_date);

            return bDate > aDate;
        });

        var count = 0;

        if(incentives.length > 8) {
            count = 8;
        } else {
            count = incentives.length;
        }

        for(var i = 0; i < count; i++) {
            if(incentives[i].units.length > 0) {
                sortedIncentives.push(incentives[i]);
            }
        }
    }

    if(typeof whatsNew !== 'undefined') {
        sortedIncentives = [].concat(sortedIncentives, whatsNew);

        sortedIncentives.sort((a, b) => {
            var aDate;
            if(typeof a.start_date !== 'undefined') {
                aDate = a.start_date;
            } else {
                aDate = a.suspected_release_date;
            }

            var bDate;
            if(typeof b.start_date !== 'undefined') {
                bDate = b.start_date;
            } else {
                bDate = b.suspected_release_date;
            }

            return bDate > aDate;
        });
    }

    function updateSize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', updateSize);

        return () => {
            window.removeEventListener('resize', updateSize);
        }
    });

    if(width > 899) {
        history.push('/dashboard');
    }
    
    function returnListComponent(item, key) {
        if(typeof item.suspected_release_date !== 'undefined') {
            return (
                <UpcomingProject
                    key={key}
                    image={item.image}
                    heading={item.title}
                    register={item.register_link}
                />
            );
        }

        if(typeof item.project !== 'undefined' && typeof item.project.id !== 'undefined') {
            return (
                <Incentive
                    key={key}
                    project={item.project.id}
                    points={item.points}
                    units={item.units}
                    projectName={item.project.name}
                    startDate={item.start_date}
                    endDate={item.end_date}
                    date
                />
            );
        }
    }

    return (
        <div className="WhatsNew">
            <h2>What's New</h2>
            <div className="new-incentives-container">
                {sortedIncentives.map((item, key) => 
                    returnListComponent(item, key)
                )}
            </div>
        </div>
    );
}

export default WhatsNew;