import './LeftUserSidebar.scss';
import React from 'react';
import { useDispatch } from 'react-redux';
import { userLogout } from '../../redux/actions/userActions';
import LargeUserProfile from '../LargeUserProfile/LargeUserProfile';
import LeftUserSidebarLink from '../LeftUserSidebarLink/LeftUserSidebarLink';
import UserLogout from '../UserLogout/UserLogout';
import { useHistory, useLocation } from 'react-router-dom';

function LeftUserSidebar(props) {
    var dispatch = useDispatch();
    var location = useLocation();

    var links = [
        {
            icon: "/img/profile-details-icon.svg",
            title: "Profile Details",
            to: "/profile/details",
            desc: "Personal and brokerage information."
        },
        {
            icon: "/img/security-icon.svg",
            title: "Security",
            to: "/profile/security",
            desc: "Change your password."
        },
        {
            icon: "/img/email-icon.svg",
            title: "Email Preferences",
            to: "/profile/preferences",
            desc: "Update email notifications."
        }
    ]

    var currentLocation = -1;

    for(var i = 0; i < links.length; i++) {
        if(location.pathname === links[i].to)
            currentLocation = i;
    }

    return (
        <div className="LeftUserSidebar">
            <LargeUserProfile />
            <div className="links">
                {links.map((link, key) => 
                    <LeftUserSidebarLink
                        key={key}
                        to={link.to}
                        icon={link.icon}
                        title={link.title}
                        active={key === currentLocation}
                        description={link.desc}
                    />
                )}
            </div>
           <UserLogout />
        </div>
    );
}

export default LeftUserSidebar;