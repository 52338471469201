import './LeftSideBarMainNav.scss';
import React from 'react';
import LeftSideBarIcon from './LeftSideBarIcon';
import NoticationIcon from './NotificationIcon';
import DashboardIcon from './DashboardIcon';
import PortfolioIcon from './PortfolioIcon';
import IncentiveIcon from './IncentiveIcon';

function LeftSideBarMainNav(props) {
    var hasNotification = false;

    function getIcon(icon) {
        switch(icon) {
            case 'dashboard':
                return <DashboardIcon />;
            case 'portfolio': 
                return <PortfolioIcon />;
            case 'incentive':
                return <IncentiveIcon />;
        }
    }

    function isActive(page) {
        return props.location.pathname.includes(page);
    }

    return(
        <div className="LeftSideBarMainNav">
            <LeftSideBarIcon
                to="/dashboard"
                img={getIcon('dashboard')}
                active={isActive('/dashboard')}
            >
                Dashboard
            </LeftSideBarIcon>
            <LeftSideBarIcon
                to="/portfolio"
                img={getIcon('portfolio')}
                active={isActive('/portfolio')}
            >
                Portfolio
            </LeftSideBarIcon>
            <LeftSideBarIcon
                to="/incentives"
                img={getIcon('incentive')}
                active={isActive('/incentives')}
            >
                Incentives
            </LeftSideBarIcon>
            <NoticationIcon hasNotification={hasNotification} active={isActive('/notifications')}>
                Notifications
            </NoticationIcon>
        </div>
    )
}

export default LeftSideBarMainNav;