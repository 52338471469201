import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPopupMessage } from '../../redux/actions/popupActions';
import { userRegister } from '../../redux/actions/userActions';
import Form from '../Form/Form';

function UserRegisterForm(props) {
    var dispatch = useDispatch();
    var canSubmit = useSelector(state => state.user.userRegisterCanSubmit);

    function registerNewUser(values) {
        //need to verifiy that the passwords match
        if(canSubmit) {
            if(values.password !== values.password_confirm) {
                var message = {
                    type: "error",
                    message: "The entered password values must match",
                    title: "Error"
                };

                dispatch(addPopupMessage(message));
            }
            else if(values.password.length < 8) {
                var message = {
                    type: "error",
                    message: "Password must be at least 8 characters long",
                    title: "Error"
                };

                dispatch(addPopupMessage(message));
            }
            else if(!(/\d/.test(values.password))) {
                var message = {
                    type: "error",
                    message: "The entered password must contain a number",
                    title: "Error"
                };

                dispatch(addPopupMessage(message));
            }
            else {
                dispatch(userRegister(values));
            }
        }
    }

    return (
        <Form
            fields={props.form}
            classes="UserRegisterForm"
            onSubmit={registerNewUser}
            submitting={!canSubmit}
        />
    )
}

export default UserRegisterForm;