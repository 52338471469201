import {
    CONTENT_QUERY,
    CONTENT_UPDATE,
} from '../actions/actionTypes';

const initalState = {
    pages: [],
    menu: [],
    options: {},
    contentLoaded: false
};

function content(state = initalState, action) {
    switch(action.type) {
        case CONTENT_QUERY:
            return state;
        
        case CONTENT_UPDATE:
            return Object.assign({}, state, {
                pages: action.content.pages,
                menu: action.content.menu,
                options: action.content.options,
                contentLoaded: true
            });
        default:
            return state;
    }
}

export default content;