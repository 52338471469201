class Queue {
    constructor() {
        this.nodes = [];
    }

    enqueue(item) {
        //add the item
        this.nodes.push(item);
    }

    dequeue() {
        if(this.nodes.length <= 0) return null;

        var item = this.nodes.splice(0, 1);

        return item[0];
    }

    peek() {
        if(this.nodes.length <= 0) return null;

        return this.nodes[0];
    }

    length() {
        return this.nodes.length;
    }
}

export default Queue;