import './NotificationIcon.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import NotificationIconSvg from './NotificationIconSvg';
import { useSelector } from 'react-redux';

function NoticationIcon(props) {
    //TODO:
    //for now im just going to make a boolean
    //but later we're going to need to grab the stuff out
    //of redux and determine if there are any notificaitons
    //the user needs to take a look at

    var notifications = useSelector(state => state.user.user.notifications);
    var hasNotification = false;
    if(Array.isArray(notifications)) {
        notifications.map((notification) => {
            if(notification.is_read === "n") hasNotification = true;
        });
    }
    
    var classes = "NotificationIcon";
    if(props.active) classes += " active";
    if(props.mobile) classes += " mobile";

    function handleClick() {
        if(props.setOpen) props.setOpen(false);
    }

    return (
        <Link to="/notifications" className={classes} onClick={handleClick}>
            <div className="image-wrapper">
                <NotificationIconSvg />
                {hasNotification === true &&
                    <div className="dot"></div>
                }
            </div>
            Notifications
        </Link>
    );
}

export default NoticationIcon;