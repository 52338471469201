import React from 'react';
import Form from '../Form/Form';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { userUpdateNotificationSettings } from '../../redux/actions/userActions';

function ProfilePreferencesForm(props) {
    var dispatch = useDispatch();
    var authToken = useSelector(state => state.user.authToken);
    var canSubmit = useSelector(state => state.user.userCanUpdateNotificationSettings);
    var userValues = useSelector(state => state.user.user.notificationSettings);

    var formObj = {
        points_awarded_activity: userValues.pointsAwarded,
        marling_spring_new_project_announcement: userValues.newProjects,
        marlin_spring_updates: userValues.msUpdates
    }

    function updateProfilePreferences(values) {
        dispatch(userUpdateNotificationSettings(authToken, values))
    }

    return (
        <Form
            fields={props.form}
            preValues={formObj}
            classes="ProfilePreferences"
            onSubmit={updateProfilePreferences}
            submitting={!canSubmit}
        />
    )
}

export default ProfilePreferencesForm;