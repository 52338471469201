import "./UpcomingProject.scss";
import React from 'react';

function UpcomingProject(props) {

    return (
        <div className="UpcomingProject">
            <div className="project-image">
                <img
                    src={props.image.url}
                    alt={props.image.alt}
                />
            </div>
            <div className="content-wrapper">
                <div className="tag-wrapper">
                    Coming Soon
                </div>
                <div className="heading">
                    {props.heading}
                </div>
                <a className="register-link" href={props.register} target="_blank">Register now</a>
            </div>
        </div>
    )
}

export default UpcomingProject;