import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../Form/Form';
import { userChangePassword } from '../../redux/actions/userActions';
import { addPopupMessage } from '../../redux/actions/popupActions';

function ProfileSecurityForm(props) {
    var dispatch = useDispatch();
    var token = useSelector(state => state.user.authToken);
    var canSubmit = useSelector(state => state.user.userPasswordCanChange);

    function validatePassword(values, errors) {
        var validated = true;

        if(!values.password) {
            errors['password'] = true;
            validated = false;
        }

        if(!values.new_password) {
            errors['new_password'] = true;
            validated = false;
        }
        if(!values.new_password_confirm) {
            errors['new_password_confirm'] = true; 
            validated = false;
        }

        return validated;
    }

    function updateUserPassword(values) {
        if(values.password && values.new_password && values.new_password_confirm) {
            if(values.new_password !== values.new_password_confirm) {
                var message = {
                    title: "Error",
                    message: "The entered password values must match",
                    type: "error"
                }
                dispatch(addPopupMessage(message));
            }
            else if(!(/\d/.test(values.new_password))) {
                var message = {
                    title: "Error",
                    message: "The entered password must contain a number",
                    type: "error"
                }
                dispatch(addPopupMessage(message));
            }
            else if(values.new_password.length < 8) {
                var message = {
                    title: "Error",
                    message: "The entered password must be at least 8 characters long",
                    type: "error"
                }
                dispatch(addPopupMessage(message));
            }
            else {
                dispatch(userChangePassword(token, values));
            }
        }
    }

    return(
        <Form
            fields={props.form}
            validate={validatePassword}
            classes="ProfileSecurityForm"
            onSubmit={updateUserPassword}
            submitting={!canSubmit}
        />
    );
}

export default ProfileSecurityForm;