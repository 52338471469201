import './Incentive.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { addCommas } from '../Utilities/utilities';

function Incentive(props) {
    var projectImages = useSelector(state => state.content.options.development_images);

    function projectImageByID(projectID) {
        for(var i = 0; i < projectImages.length; i++) {
            var project = projectImages[i];

            if(project.project_id == projectID) return project;
        }
    }

    var projectImage;
    var locationText;
    var projectLink;

    if(props.manual) {
        projectImage = props.projectImage;
        locationText = props.projectLocation;
        projectLink = `/portfolio/project/${safeUrl(props.projectName)}`;
    }
    else {
        projectImage = projectImageByID(props.project);
        locationText = projectImage?.location;
        projectLink = `/portfolio/project/${safeUrl(props.projectName)}`;
    }

    function fullMonth(mon) {
        switch(mon) {
            case 0:
                return "January";
            case 1:
                return "Feburary";
            case 2:
                return "March";
            case 3:
                return "April";
            case 4:
                return "May";
            case 5:
                return "June";
            case 6:
                return "July";
            case 7:
                return "August";
            case 8:
                return "September";
            case 9:
                return "October";
            case 10:
                return "November";
            case 11:
                return "December";
            default:
                return "January";
        }
    }

    function safeUrl(str) {
        var url = str.replaceAll(' ', '-');
        return url.toLowerCase();
    }

    if(props.date) {
        var sDate = new Date(props.startDate);
        var eDate = new Date(props.endDate);
        
        sDate.setMinutes(sDate.getMinutes() - sDate.getTimezoneOffset());
        eDate.setMinutes(eDate.getMinutes() - eDate.getTimezoneOffset());

        locationText = "From "+fullMonth(sDate.getMonth())+" "+sDate.getDate();
        locationText += " to "+fullMonth(eDate.getMonth())+" "+eDate.getDate();
    }

    return(
        <Link className="Incentive" to={projectLink}>
            <div className="project-image">
                <img
                    src={projectImage?.project_image?.url}
                    alt={projectImage?.project_image?.alt}
                />
            </div>
            <div className="content-wrapper">
                <div className="points-wrapper">
                    <div className="points-container">
                        <img src="/img/points-icon.svg" alt="" className="points-icon" />
                        Up to {addCommas(props.points)} points
                    </div>
                </div>
                <div className="heading">
                    {props.projectName}
                </div>
                {props.units &&
                    <div className="units">
                        Sell Units: {props.units.map((unit, key) =>
                            unit.unit_number + (key+1 !== props.units.length ? ", " : "")
                        )}
                    </div>
                }
                <div className="location">
                    {locationText}
                </div>
            </div>
        </Link>
    )
}

export default Incentive;