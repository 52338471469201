import React from 'react';
import './PointsSummary.scss';
import { useSelector } from 'react-redux';
import { addCommas } from '../Utilities/utilities';

function PointsSummary() {
    var user = useSelector(state => state.user.user);

    function getPoints(points) {
        if (points < 0) {
            return addCommas(points);
        } else if (points == 0) {
            return '-';
        } else {
            return '+' + addCommas(points);
        }
    }

    function PointsEventRow(props) {
        var points;
        var date;
        var projName;
        var floorplan;
        var sold;
        var commission;
        var broker;
        var purchasers;
        var status;
        var reason;
        var length = user?.sales?.full_summary.length;
        var last = props.index+1 === length;

        function preparePurchasersStr(str) {
            var output = str.replace(/,/g, ", ");
            return output.trim();
        }

        points = "points";
        date = "date";
        projName = "project-name";
        floorplan = props.floorPlan && props.unitNumber ? `unit` : `unit empty`;
        sold = props.soldPrice ? "price" : "price empty";
        commission = props.commission ? "commission" : "commission empty";
        broker = props.broker ? "broker" : "broker empty";
        purchasers = props.purchasers ? "purchaser" : "purchaser empty";
        status = props.status ? "status" : "status empty";
        reason = props.reason ? "points-reason" : "points-reason empty";

        if(last) {
            points += " last";
            date  += " last";
            projName += " last";
            floorplan += " last";
            sold += " last";
            commission += " last";
            broker += " last";
            purchasers += " last";
            status += " last";
            reason += " last";
        }

        return (
            <React.Fragment>
                <span className={points}>
                    {getPoints(props.points)}
                </span>
                <span className={date}>
                    {props.date}
                </span>
                <span className={status}>
                    {props.status ? props.status : "-"}
                </span>
                <span className={projName}>
                    {props.project}
                </span>
                <span className={floorplan}>
                    {props.floorPlan && props.unitNumber ? `${props.floorPlan} : ${props.unitNumber}` : <div className="center-line">-</div>}
                </span>
                <span className={sold}>
                    {props.soldPrice ? `$${addCommas(props.soldPrice)}` : "-"}
                </span>
                <span className={commission}>
                    {props.commission ? props.commission + "%" : "-"}
                </span>
                <span className={purchasers}>
                    {props.purchasers ? preparePurchasersStr(props.purchasers) : "-"}
                </span>
                <span className={reason}>
                    {props.reason ? 'Reason: ' + props.reason : '-'}
                </span>
            </React.Fragment>
        );
    }

    return (
        <div className="PointsSummary" id="PointsSummary">
            <div className="earnings-container">
                <span className="earnings">
                    Points
                </span>
                <span className="earnings">
                    Date
                </span>
                <span className="earnings">
                    Status
                </span>
                <span className="earnings">
                    Project
                </span>
                <span className="earnings">
                    Unit Sold
                </span>
                <span className="earnings">
                    Sale Price
                </span>
                <span className="earnings">
                    Commission
                </span>
                <span className="earnings">
                    Purchaser
                </span>
                <span className="earnings">
                    Notes
                </span>
            </div>
            <div className="points-events">
                {user?.sales?.full_summary.map((point, key) => 
                    <PointsEventRow
                        key={key}
                        index={key}
                        date={point.date}
                        project={point.project}
                        broker={`${user.firstname} ${user.lastname}`}
                        reason={point.reason}
                        points={point.points}
                        status={point.status}
                        purchasers={point.purchasers}
                        soldPrice={point.soldPrice}
                        commission={point.commission}
                        unitNumber={point.unitNumber}
                        floorPlan={point.floorPlan}
                    />
                )}
            </div>
        </div>
    );
}

export default PointsSummary;
