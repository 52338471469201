import "./MobileBottomBar.scss";
import React from 'react';
import { useSelector } from "react-redux";
import MobileBottomBarIcon from '../LeftSideBarMainNav/LeftSideBarIcon';
import NoticationIcon from '../LeftSideBarMainNav/NotificationIcon';
import DashboardIcon from '../LeftSideBarMainNav/DashboardIcon';
import PortfolioIcon from '../LeftSideBarMainNav/PortfolioIcon';
import IncentiveIcon from '../LeftSideBarMainNav/IncentiveIcon';
import MobileUserProfile from '../MobileUserProfile/MobileUserProfile';
import { useLocation } from "react-router";
import LeftSideBarPrivacyNav from '../LeftSideBarPrivacyNav/LeftSideBarPrivacyNav';
import LeftUserSidebarCopyRight from '../LeftUserSidebarCopyRight/LeftUserSidebarCopyRight';

function MobileBottomBar(props) {
    var hasNotification = false;
    var location = useLocation();

    var verified = useSelector(state => state.user.userVerified);
    var confirmed = useSelector(state => state.user.userConfirmed);

    var classes = "MobileBottomBar";
    if(!props.loggedIn) classes += " logged-out";
    if(location.pathname === "/") classes += " hidden";

    function getIcon(icon) {
        switch(icon) {
            case 'dashboard':
                return <DashboardIcon />;
            case 'portfolio': 
                return <PortfolioIcon />;
            case 'incentive':
                return <IncentiveIcon />;
        }
    }

    function isActive(page) {
        return location.pathname.includes(page);
    }
    
    return (
        <div className={classes}>
            {props.loggedIn && verified && confirmed &&
                <div className="nav-container">
                    <MobileBottomBarIcon
                        to="/dashboard"
                        img={getIcon('dashboard')}
                        active={isActive('/dashboard')}
                        mobile
                        setOpen={props.setOpen}
                    >
                        Dashboard
                    </MobileBottomBarIcon>
                    <MobileBottomBarIcon
                        to="/portfolio"
                        img={getIcon('portfolio')}
                        active={isActive('/portfolio')}
                        mobile
                        setOpen={props.setOpen}
                    >
                        Portfolio
                    </MobileBottomBarIcon>
                    <MobileBottomBarIcon
                        to="/incentives"
                        img={getIcon('incentive')}
                        active={isActive('/incentives')}
                        mobile
                        setOpen={props.setOpen}
                    >
                        Incentives
                    </MobileBottomBarIcon>
                    <NoticationIcon
                        hasNotification={hasNotification}
                        active={isActive('/notifications')}
                        mobile
                        setOpen={props.setOpen}
                    >
                        Notifications
                    </NoticationIcon>
                    <MobileUserProfile />
                </div>
            }
            {!props.loggedIn && verified && confirmed &&
                <React.Fragment>
                    <LeftSideBarPrivacyNav blue />
                    <LeftUserSidebarCopyRight />
                </React.Fragment>
            }
        </div>
    );
}

export default MobileBottomBar;