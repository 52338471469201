import React from 'react';
import { useSelector } from 'react-redux';
import ForgotPasswordForm from "./ForgotPasswordForm";
import LeftSidebar from '../LeftSidebar/LeftSidebar';
import LeftSidebarLight from '../LeftSidebarLight/LeftSidebarLight';
import './ForgotPassword.scss';

function ForgotPassword() {
	const content = useSelector((state) => state.content.pages?.forgot_password);

    return ( 
        <div className="ForgotPassword">
            <LeftSidebar />
            <LeftSidebarLight>
                <h1>{content?.title}</h1>
                <p>{content?.intro_copy}</p>
            </LeftSidebarLight>
            <div className="forgot-password-content-container">
                <div className="form-container">
                    {content?.form && 
                        <ForgotPasswordForm
                            form={content.form}
                        />
                    }
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;
