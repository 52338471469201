import "./ProfileBackSection.scss";
import React from 'react';
import { Link } from "react-router-dom";

function ProfileBackSection(props) {

    return (
        <div className="ProfileBackSection">
            <Link to="/profile" className="back-arrow">
                <img src="/img/back-arrow-arrow.svg"  alt="" />
                <div>
                    Back to Profile & Settings
                </div>
            </Link>
            <img src={props.image} alt="" />
        </div>
    )
}

export default ProfileBackSection;