import './LeftUserRegisterSidebar.scss';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LeftSideBarPrivacyNav from '../LeftSideBarPrivacyNav/LeftSideBarPrivacyNav';
import LeftUserSidebarCopyRight from '../LeftUserSidebarCopyRight/LeftUserSidebarCopyRight';

function LeftUserRegisterSidebar(props) {
    const content = useSelector((state) => state.content.pages?.create_account);
    var [winWidth, setWinWidth] = useState(window.innerWidth);

    function updateWidth() {
        setWinWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    });

    var mobile = winWidth < 481;

    return(
        <div className="LeftUserRegisterSidebar">
            <h1>{content?.title}</h1>
            <p>{content?.intro_copy}</p>
            {!mobile &&
                <React.Fragment>
                    <LeftSideBarPrivacyNav blue />
                    <LeftUserSidebarCopyRight />
                </React.Fragment>
            }
        </div>
    )
}

export default LeftUserRegisterSidebar;