import './Portfolio.scss';
import { useSelector } from 'react-redux';
import React, { useState, useRef, useEffect } from 'react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

import Incentive from '../Incentive/Incentive';
import LeftSidebar from '../LeftSidebar/LeftSidebar';
import IncentiveFilter from '../IncentiveFilter/IncentiveFilter';
import mapStyles from './mapstyle.json';
import PortfolioUpcomingProject from '../PortfolioUpcomingProject/PortfolioUpcomingProject';

function Portfolio() {
    var mapContainer = useRef();

    var projects = useSelector(state => state.content.options.development_images);
    var projectNames = useSelector(state => state.content.options.projects_select);
    var manualProjects = useSelector(state => state.content.options.manual_projects);
    var incentives = useSelector(state => state.content.options.incentives);
    var iconStr = useSelector(state => state.content.options.map_icon);
    var whatsnew = useSelector(state => state.content.options.upcoming_projects);
    var noIncentiveMessage = useSelector(state => state.content.pages.incentives.no_incentives_message);

    var displayUpcoming = [];

    var [gMap, setgMap] = useState(null);
    var [locationValue, setLocationValue] = useState("");
    var [rewardsValue, setRewardsValue] = useState("");
    var [renderHack, setRenderHack] = useState(0);
    var [winWidth, setWinWidth] = useState(window.innerWidth);
    var markers = [];
    var displayProjects = [];
    var filters = [];

    var projectCities = [];

    var rewardsOptions = [
        {
            label: "0 - 1,000 Points",
            value: 0,
            disabled: false
        },
        {
            label: "1,000 - 2,000 Points",
            value: 1,
            disabled: false
        },
        {
            label: "2,000+ Points",
            value: 2,
            disabled: false
        }
    ];

    if(!anyIncentives()) {
        rewardsOptions = [
            {
                label: noIncentiveMessage,
                value: "",
                disabled: true
            }
        ]
    }

    function tallyProjectPoints(project_id) {
        var total = parseInt(0);

        for(var i = 0; i < incentives.length; i++) {
            var incentive = incentives[i];

            if(project_id == incentive.project.id) {
                for(var j = 0; j < incentive.units.length; j++) {
                    total = parseInt(total) + parseInt(incentive.points);
                }
            }
        }

        return total;
    }

    function tallyMaunalProjectPoints(manualID) {
        var total = parseInt(0);

        var incentives = manualProjects[manualID].incentives;

        for(var i = 0; i < incentives.length; i++) {
            var points = parseInt(incentives[i].points) * parseInt(incentives[i].units.length);
            if(points > 0) total += points;
        }

        return total;
    }

    function anyIncentives() {
        //check if there's no incentives at all literally fuck you kristina
        var any = false;

        if(incentives.length > 0) any = true;

        for(var i = 0; i < manualProjects.length; i++) {
            //check for any incentives in here as well
            if(manualProjects[i].incentives.length > 0) any = true;
        }
        
        return any;
    }

    if(projects && incentives) {
        for(var i = 0; i < projects.length; i++) {
            var pointsTotal = 0;
            var projectName = 0;

            for(var j = 0; j < incentives.length; j++) {
                if(incentives[j].project.id == projects[i].project_id) {
                    pointsTotal = parseInt(pointsTotal) + parseInt(incentives[j].points);
                }
            }

            for(j = 0; j < projectNames.length; j++) {
                if(projectNames[j].value == projects[i].project_id) {
                    projectName = j;
                }
            }

            projects[i].pointsTotal = pointsTotal;
            projects[i].projectName = projectNames[projectName].label;

            //setup the filter
            var cityItem = {
                value: projects[i].location,
                label: projects[i].location
            };

            projectCities.push(cityItem);

            var marker = {
                lat: parseFloat(projects[i].geolocation.lat),
                lng: parseFloat(projects[i].geolocation.lng),
                title: projectNames[projectName].label,
                city: projects[i].location
            }

            markers.push(marker);
        }

        //check the filters now
        if(locationValue !== "") {
            filters.push({
                project: true,
                value: locationValue
            });
        }

        if(rewardsValue !== "") {
            filters.push({
                reward: true,
                value: rewardsValue
            });
        }

        //now filter the projects to match the filters to start
        var newProj = [];

        for(var i = 0; i < projects.length; i++) {
            var canAdd = true;

            for(var j = 0; j < filters.length; j++) {
                if(filters[j]?.project) {
                    //if(filters[j].value != projects[i].project_id)
                    if(filters[j].value !== projects[i].location)
                    canAdd = false;
                }
                if(filters[j]?.reward) {

                    switch(filters[j].value) {
                        case "0":
                            if(tallyProjectPoints(projects[i].project_id) > 1000)
                                canAdd = false;
                            break;
                        case "1":
                            if(
                                tallyProjectPoints(projects[i].project_id) < 1001 ||
                                tallyProjectPoints(projects[i].project_id) > 2001
                            )
                                canAdd = false;
                            break;
                        case "2":
                            if(tallyProjectPoints(projects[i].project_id) < 2001)
                                canAdd = false;
                            break;
                    }
                }
            }

            if(gMap) {
                if(!gMap.checkMarkerInView(projects[i].projectName) && winWidth > 899) {
                    canAdd = false;
                }
            }

            if(canAdd) newProj.push(projects[i]);
        }

        for(var i = 0; i < manualProjects.length; i++) {
            var addCity = true;

            for(var j = 0; j < projectCities.length; j++) {
                if(manualProjects[i].location === projectCities[j].value) {
                    addCity = false;
                }
            }

            if(addCity) {
                projectCities.push({
                    value: manualProjects[i].location,
                    label: manualProjects[i].location
                });
            }

            var marker = {
                lat: parseFloat(manualProjects[i].geolocation.latitude),
                lng: parseFloat(manualProjects[i].geolocation.longitude),
                title: manualProjects[i].project_name,
                city: manualProjects[i].location,
                ID: i
            };

            markers.push(marker);

            var canAdd = true;

            for(var j = 0; j < filters.length; j++) {
                if(filters[j]?.project) {
                    if(filters[j].value !== manualProjects[i].location) {
                        canAdd = false;
                    }
                }
                if(filters[j]?.reward) {
                    switch(filters[j].value) {
                        case "0":
                        if(tallyMaunalProjectPoints(i) > 1000)
                            canAdd = false;
                        break;
                        case "1":
                            if(
                                tallyMaunalProjectPoints(i) < 1001 ||
                                tallyMaunalProjectPoints(i) > 2001
                            )
                                canAdd = false;
                            break;
                        case "2":
                            if(tallyMaunalProjectPoints(i) < 2001)
                                canAdd = false;
                            break;
                    }
                }
            }

            if(gMap) {
                if(!gMap.checkMarkerInView(manualProjects[i].project_name) && winWidth > 899) {
                    canAdd = false;
                }
            }

            if(canAdd) {
                manualProjects[i].manual = true;
                manualProjects[i].ID = i;
                newProj.push(manualProjects[i]);
            }
        }

        //now we need to add in the upcoming projects to the dropdown
        

        displayProjects = newProj;

        for(var i = 0; i < whatsnew.length; i++) {
            var canAdd = true;

            projectCities.push({
                value: whatsnew[i].location,
                label: whatsnew[i].location
            });

            for(var j = 0; j < filters.length; j++) {
                if(filters[j]?.project) {
                    if(whatsnew[i].location !== filters[j].value)
                    canAdd = false;
                }
                if(filters[j]?.reward) {
                    if(filters[j].value !== "")
                    canAdd = false;
                }
            }

            if(canAdd) {
                displayUpcoming.push(whatsnew[i]);
            }
        }
    }

    function returnListComponent(item, key) {
        if(typeof item.manual !== "undefined") {
            return(
                <Incentive
                    key={key}
                    points={tallyMaunalProjectPoints(item.ID)}
                    projectName={item.project_name}
                    manualLink={item.ID}
                    projectImage={{project_image: item.project_image}}
                    projectLocation={item.location}
                    manual
                />
            )
        }
        return (
            <Incentive
                key={key}
                project={item.project_id}
                points={tallyProjectPoints(item.project_id)}
                projectName={item.projectName}
                link
            />
        )
    }

    function startMap() {
        //this will be the starting point for my js that will run seperate of the
        //reactjs render loop. If i need to interact with reactjs i will
        //do so by modifying the state.

        var map = {};
        map.markers = [];
        map.removeMarkers = [];
        map.cluster = null;
        map.markersInScreen = 0;
        
        map.createMap = function() {
            map.map = new window.google.maps.Map(mapContainer.current, {
                //im keeping this in case i need to set it back to how it was
                //zoom: 10,
                zoom: 12,
                center: {
                    //im keeping this in case i need to set it back to how it was
                    //lat: 43.651070,
                    //lng: -79.347015
                    lat: 43.65497545557343,
                    lng:-79.44468952017787
                },
                styles: mapStyles
            });

            map.map.addListener('dragend', () => {
                this.updateMarkersInView();
            });

            this.cluster = new MarkerClusterer({
                map: this.map,
                markers: this.markers
            });
        }

        map.updateMarkersInView = function() {
            var num = 0;

            for(var i = 0; i < this.markers.length; i++) {
                //check if each marker is in screen and count up
                if(this.map.getBounds().contains(this.markers[i].getPosition()))
                num++;
            }

            this.markersInScreen = num;
            setRenderHack(renderHack++);
        }

        map.checkMarkerInView = function(title) {
            var marker = null;
            var inView = true;

            for(var i = 0; i < this.markers.length; i++) {
                if(this.markers[i].title == title) {
                    marker = this.markers[i];
                }
            }

            if(typeof this.map !== 'undefined' && marker) {
                var bounds = this.map.getBounds()
                
                if(bounds) {
                    inView = this.map.getBounds().contains(marker.getPosition());
                }
            }

            return inView;
        }

        map.addMarkers = function(markers, icon, filters = []) {
            var activeMarkers = this.applyFilters(markers, filters);
            this.removeMarkers = this.markers;
            this.markers = [];

            for(var i = 0; i < activeMarkers.length; i++) {
                var lat = activeMarkers[i].lat;
                var lng = activeMarkers[i].lng;

                var mark = new window.google.maps.Marker({
                    position: { lat: lat, lng: lng },
                    map: this.map,
                    title: activeMarkers[i].title,
                    icon: icon
                });

                var infowindow = new window.google.maps.InfoWindow({
                    content: "&nbsp;&nbsp;" + activeMarkers[i].title + "&nbsp;&nbsp;",
                    disableAutoPan: true
                });

                infowindow.open({
                    anchor: mark,
                    map: this.map,
                    shouldFocus: false,
                });

                this.markers.push(mark);
            }

            if(this.cluster)
            this.cluster.markers = this.markers;
        }

        map.clearMarkers = function() {
            for(var i = 0; i < this.removeMarkers.length; i++) {
                this.removeMarkers[i].setMap(null);
            }

            this.removeMarkers = [];
        }

        map.fixOverlapMarkers = function(icon) {
            //loop through the active markers to look for overlaps. I will need to do this on map zoom in and
            //zoom out as well

            var overlaps = [];

            for(var i = 0; i < this.markers.length; i++) {
                for(var j = 0; j < this.markers.length; j++) {
                    //we are going to be checking all of the markers against the other markers
                    if(i !== j) {
                        //check if the markers overlap
                        if(this.markers[i].getPosition().contains(this.markers[j].getPosition())) {
                            console.log('overlap');
                        }

                        overlaps.push(i);
                    }
                }
            }
        }

        map.applyFilters = function(markers, filters = []) {
            var activeMarkers = [];

            for(var i = 0; i < markers.length; i++) {
                var canAdd = true;

                //apply filters
                for(var j = 0; j < filters.length; j++) {
                    if(filters[j]?.project) {
                        if(filters[j].value != markers[i].city)
                        canAdd = false;
                    }
                    if(filters[j]?.reward) {
                        
                        if(typeof projects[i] === 'undefined') {
                            //if its not thre then its manual and will have an extra field to hold the ID
                            switch(filters[j].value) {
                                case "0":
                                    if(tallyMaunalProjectPoints(markers[i].ID) > 1000)
                                        canAdd = false;
                                break;
                                case "1":
                                    if(
                                        tallyMaunalProjectPoints(markers[i].ID) < 1001 ||
                                        tallyMaunalProjectPoints(markers[i].ID) > 2001
                                    )
                                        canAdd = false;
                                break;
                                case "2":
                                    if(tallyMaunalProjectPoints(markers[i].ID) < 2001)
                                        canAdd = false;
                                break;
                            }
                        }
                        else {
                            switch(filters[j].value) {
                                case "0":
                                    if(tallyProjectPoints(projects[i].project_id) > 1000)
                                        canAdd = false;
                                    break;
                                case "1":
                                    if(tallyProjectPoints(projects[i].project_id) < 1001 ||
                                        tallyProjectPoints(projects[i].project_id) > 2001
                                    )
                                        canAdd = false;
                                    break;
                                case "2":
                                    if(tallyProjectPoints(projects[i].project_id) < 2001)
                                        canAdd = false;
                                    break;
                            }
                        }
                    }
                }

                if(canAdd) activeMarkers.push(markers[i]);
            }

            return activeMarkers;
        }

        map.createMap();
        return map;
    }

    //mapp effects
    useEffect(() => {
        if(!window?.google?.maps) {
            var apiKey = "AIzaSyB_7Iun-V-dfu-lra8Rxl4bdV015oakQKI";
            var googleMapScript = document.createElement('script');
            googleMapScript.async = true;
            googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${
                apiKey    
            }&libraries=place`;

            document.body.appendChild(googleMapScript);
            
            googleMapScript.addEventListener('load', () => {
                var map = startMap();
                map.addMarkers(markers, iconStr);

                map.map.addListener('zoom_changed', () => {
                    if(gMap) {
                        gMap.updateMarkersInView();
                    }
                });
                
                map.map.addListener('bounds_changed', () => {
                    if(gMap) {
                        gMap.updateMarkersInView();  
                    }
                });

                setgMap(map);
            });
        }
        else {
            setgMap(startMap());
        }
    }, []);

    useEffect(() => {
        if(gMap) {
            gMap.addMarkers(markers, iconStr, filters);
            gMap.clearMarkers();
        }
    });

    function setWidth () {
        setWinWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', setWidth);
        return () => window.removeEventListener('resize', setWidth);
    });

    //render 
    return (
        <div className="Portfolio">
            <LeftSidebar />
            <div className="portfolio-content-container">
                <div className="map-side-container">
                    <IncentiveFilter
                        label="Select Location"
                        options={projectCities}
                        update={setLocationValue}
                    />
                    <IncentiveFilter
                        label="Select Incentives"
                        options={rewardsOptions}
                        update={setRewardsValue}
                    />
                    <div className="portfolio-map-container" ref={mapContainer}>
                    </div>
                </div>
                <div className="project-side-container">
                    <div className="upcoming-projects-wrapper">
                        {whatsnew && displayUpcoming.map((proj, key) => 
                            <PortfolioUpcomingProject proj={proj} key={key} />
                        )}
                    </div>
                    <div className="projects-wrapper">
                        {displayProjects && displayProjects.map((proj, key) => 
                            returnListComponent(proj, key)
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Portfolio;