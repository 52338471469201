import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LeftSidebar from '../LeftSidebar/LeftSidebar';
import { userRequestNewVerificationCode } from '../../redux/actions/userActions';
import LeftSidebarLight from '../LeftSidebarLight/LeftSidebarLight';
import LeftSideBarPrivacyNav from '../LeftSideBarPrivacyNav/LeftSideBarPrivacyNav';
import LeftUserSidebarCopyRight from '../LeftUserSidebarCopyRight/LeftUserSidebarCopyRight';
import './AccountCreated.scss';
import UserLogout from '../UserLogout/UserLogout';

function AccountCreated() {
	const content = useSelector((state) => state.content.pages?.account_created);
    var history = useHistory();
    var dispatch = useDispatch();

    var loggedIn = useSelector(state => state.user.userLoggedIn);
    var verified = useSelector(state => state.user.userVerified);
    var token = useSelector(state => state.user.authToken);

    var canRequestNewEmailVerification = useSelector(state => state.user.userCanRequestNewVerificationEmail);
    var linkClasses = "link";
    if(canRequestNewEmailVerification === false) linkClasses += " unactive";

    var [winWidth, setWinWidth] = useState(window.innerWidth);
    function updateWidth() {
        setWinWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    });

    if(loggedIn === true) {
        if(verified === true)
        history.push('/account-verified');
    }
    else {
        history.push('/');
    }
    
   
    function RequestNewVerificationLink(e) {
        if(canRequestNewEmailVerification === true)
            dispatch(userRequestNewVerificationCode(token));
    }

    return (
        <div className="AccountCreated">
            <LeftSidebar />
            <LeftSidebarLight>
                <h2>{content?.title}</h2>
                {winWidth > 899 &&
                    <LeftSideBarPrivacyNav blue />
                }
                <UserLogout />
                {winWidth > 899 &&
                    <LeftUserSidebarCopyRight />
                }
            </LeftSidebarLight>
            <div className="content-container">
                <div className="account-created-wrapper">
                    <img src="/img/email-sending-icon.svg" alt="" />
                    <h2 className="heading">{content.gold_heading}</h2>
                    <p>{content.gold_copy}</p>
                    <div className={linkClasses} onClick={RequestNewVerificationLink}>{content.gold_cta_label}</div>
                </div>
            </div>
        </div>
    );
}

export default AccountCreated;
