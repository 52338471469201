import React from 'react';
import './Row.scss';

function Row(props) {
    return <div className={'row' + (props.className ? ' ' + props.className : '')}>
				 {props.children}
			</div>;
}

export default Row;
