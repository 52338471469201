import {
    USER_REGISTER,
    USER_REGISTER_QUERY,
    USER_LOGIN,
    USER_LOGIN_QUERY,
    USER_VERIFY,
    USER_VERIFY_FAILED,
    USER_PREVERIFY_LOGIN,
    USER_VERIFY_QUERY,
    USER_CHANGE_PASSWORD,
    USER_CHANGE_PASSWORD_QUERY,
    USER_UPDATE_VALUES,
    USER_UPDATE_VALUES_QUERY,
    USER_UPDATE_PROFILE_IMAGE,
    USER_UPDATE_PROFILE_IMAGE_QUERY,
    USER_LOGIN_ATTEMPT_FAILED,
    USER_REGISTER_ATTEMPT_FAILED,
    USER_PROFILE_SUBMIT_ATTEMPT_FAILED,
    USER_NEW_EMAIL_VERIFICATION_REQUEST_STARTED,
    USER_NEW_EMAIL_VERIFICATION_REQUEST_COMPLETE,
    USER_PROFILE_IMAGE_UPDATE_FAILED,
    USER_LOGOUT,
    USER_UPDATE_NOTIFICATION_SETTINGS_QUERY,
    USER_UPDATE_NOTIFICATION_SETTINGS,
    USER_UPDATE_NOTIFICATION_SETTINGS_VALUES,
    USER_UPDATE_CONTENT
} from './actionTypes';
import apiService from '../../services/apiService';
import cookieService from '../../services/cookieService';
import { addPopupMessage } from './popupActions';

function userRegisterFailed() {
    return {
        type: USER_REGISTER_ATTEMPT_FAILED
    }
}

function userRegisterNotify() {
    return {
        type: USER_REGISTER_QUERY
    }
}

function userRegisterResults(token, user, verified, confirmed) {
    return {
        type: USER_REGISTER,
        token,
        user,
        verified,
        confirmed
    }
}

function verificationCodeRequestStarted() {
    return {
        type: USER_NEW_EMAIL_VERIFICATION_REQUEST_STARTED
    }
}

function verificationCodeRequestComplete() {
    return {
        type: USER_NEW_EMAIL_VERIFICATION_REQUEST_COMPLETE
    }
}

export function userRequestNewVerificationCode(token) {
    return function(dispatch) {
        dispatch(verificationCodeRequestStarted());

        apiService.post('user-request-new-verification-code', {token: token})
        .then(function(response) {
            var message = {};

            if(response.data.success === true) {
                message.title = "Success";
                message.message = "Check your email for a new verification code";
                message.type = "success";
            }
            else {
                message.title = "Error";
                message.message = "An error has occurred try reloading this page";
                message.type = "error";
            }

            dispatch(verificationCodeRequestComplete());
            dispatch(addPopupMessage(message));
        })
        .catch(function(err) {
            console.error(err);
            var message = {};

            message.title = "Error";
            message.message = "An error has occurred try reloading this page";
            message.type = "error";

            dispatch(addPopupMessage(message));
            dispatch(verificationCodeRequestComplete());
        });
    }
}

export function userRegister(registerData) {
    return function(dispatch) {
        dispatch(userRegisterNotify());

        apiService.post('user-register', registerData)
        .then(function(response) {
            if(response.data.success === true) {
                dispatch(userRegisterResults(response.data.token, response.data.user, response.data.verified, response.data.confirmed));
            } else {
                var message = {
                    title: "Error",
                    message: "Email address not available. Please try again.",
                    type: "error"
                };

                dispatch(addPopupMessage(message));
                dispatch(userRegisterFailed());
            }
        })
        .catch(function(err) {
            console.error(err);
        });
    }
}

export function userSetNotificationReadStatus(notification_id, status) {
    return function(dispatch) {
        var user = cookieService.getCookie('asc_user');

        if(user) {
            user = JSON.parse(user);
        }

        apiService.post('user-set-notification-read-status', {user_id: user.ID, notification_id: notification_id, status: status})
        .catch(function(err) {
            console.error(err);
        });
    }
}

function userLoginAttemptFailed() {
    return {
        type: USER_LOGIN_ATTEMPT_FAILED
    }
}

//notifcation function to let redux know we attempted to login
function userLoginNotify() {
    return {
        type: USER_LOGIN_QUERY
    }
}

//fires when the login process is complete the api has returned a response
function userLoginUpdate(token, user, verified, confirmed) {
    return {
        type: USER_LOGIN,
        token,
        user,
        verified,
        confirmed
    }
}

export function userLogin(loginData) {
    return function(dispatch) {
        dispatch(userLoginNotify());
        //now run the api
        apiService.post('user-login', loginData)
        .then(function(response) {
            if(response.data.success === true) {
                dispatch(userLoginUpdate(response.data.token, response.data.user, response.data.verified, response.data.confirmed));
            }
            else {
                //dispatch a popup message describing the error
                var message = {
                    title: "Login Error",
                    message: response.data.errors,
                    type: "error"
                };
                dispatch(addPopupMessage(message));
                dispatch(userLoginAttemptFailed());
            }
        })
        .catch(function(err) {
            console.error(err);
        });
    }
}

function userLoggedOut() {
    return {
        type: USER_LOGOUT
    }
}


export function userLogout() {
    return function(dispatch) {
        dispatch(userLoggedOut());
        apiService.post('user-logout', )
    }
}

function userTokenVerifyNotify() {
    return {
        type: USER_VERIFY_QUERY
    }
}

function userPreVerifyLogin(user) {
    return {
        type: USER_PREVERIFY_LOGIN,
        user
    }
}

export function userVerifyFailed() {
    return {
        type: USER_VERIFY_FAILED
    }
}

function userTokenVerify(user, verified, confirmed) {
    return {
        type: USER_VERIFY,
        user,
        verified,
        confirmed
    }
}

export function checkUserLoggedIn() {
    return function(dispatch) {
        dispatch(userTokenVerifyNotify);

        var user = cookieService.getCookie('asc_user');

        if(user) {
            user = JSON.parse(user);

            var data = {token: user.token}

            //set the active user to whatever we got from the cookie before trying
            //the verify the login from the serve . IF they do not have a valid key
            //they will be getting kicked out of the app. I feel this is more elegant
            //then the way i had it working before
            dispatch(userPreVerifyLogin(user));

            apiService.post('user-token-verify', data)
            .then(function(response) {
                if(response.data.success === true)
                    dispatch(userTokenVerify(response.data.user, response.data.verified, response.data.confirmed));
                else {
                    //we need to log the user out
                    cookieService.setCookie('asc_user', '');
                    dispatch(userVerifyFailed());
                    dispatch(userLogout(data));
                }
            })
            .catch(function(err){
                console.error(err);
            });
        }
        else {
            dispatch(userVerifyFailed());
        }
    }
}

function userContentUpdateValues(newUser) {
    return {
        type: USER_UPDATE_CONTENT,
        newUser
    }
}

export function updateUserContent() {
    return function(dispatch) {
        var user;
        user = cookieService.getCookie('asc_user');
        if(user) user = JSON.parse(user);
        if(user) {
            var data = {token: user.token}

            apiService.post('user-update-content', data)
            .then(function(response) {
                if(response.data.success === true)
                    dispatch(userContentUpdateValues(response.data.user));
                else {
                    cookieService.setCookie('asc_user', '');
                    dispatch(userVerifyFailed());
                    dispatch(userLogout(data));
                }
            })
            .catch(function(err) {
                console.error(err);
            });
        }
        else {
            dispatch(userVerifyFailed());
        }
    }
}


function userChangePasswordNotify() {
    return {
        type: USER_CHANGE_PASSWORD_QUERY
    }
}

function userChangePasswordResults() {
    return {
        type: USER_CHANGE_PASSWORD,
    }
}

export function userChangePassword(token, passwordData) {
    return function(dispatch) {
        dispatch(userChangePasswordNotify());

        var data = {
            token: token,
            password: passwordData
        }
        
        apiService.post('user-change-password', data)
        .then(function(response) {
            if(response.data.success === true) {
                var message = {
                    title: "Password Changed",
                    message: "Your password has successfully been changed",
                    type: "success"
                };

                dispatch(addPopupMessage(message));
            }
            else {
                var message = {
                    title: "Error",
                    message: "Your password was not updated",
                    type: "error"
                };

                dispatch(addPopupMessage(message));
            }
            dispatch(userChangePasswordResults())
        });
    }
}

function userProfileValuesUpdateFailed() {
    return {
        type: USER_PROFILE_SUBMIT_ATTEMPT_FAILED
    }
}

function userUpdateValuesNotify() {
    return {
        type: USER_UPDATE_VALUES_QUERY
    }
}

function userUpdateValuesResponse(user) {

    return {
        type: USER_UPDATE_VALUES,
        user: user
    }
}

export function userUpdateValues(token, newUserData) {
    return function(dispatch) {
        dispatch(userUpdateValuesNotify());

        var data = {
            token: token,
            data: newUserData
        };

        apiService.post('user-update-profile-values', data)
        .then(function(response) {
            //should have user data here
            var message;

            if(response.data.success === true) {
                dispatch(userUpdateValuesResponse(response.data.user));

                message = {
                    title: "Profile Updated",
                    message: "Your profile details have been updated",
                    type: "success"
                };

            }
            else {
                message = {
                    title: "Error",
                    messge: "Your profile was not updated",
                    type: "error"
                };
                dispatch(userProfileValuesUpdateFailed());
            }

            dispatch(addPopupMessage(message));
        })
        .catch(function(error) {
            console.error(error);
        });
    }
}

function profileImageupdateNotify() {
    return {
        type: USER_UPDATE_PROFILE_IMAGE_QUERY
    }
}

export function requestProfileImageUpdate(formData) {
    return function(dispatch) {
        dispatch(profileImageupdateNotify());
        apiService.post('upload-user-profile', formData)
        .then((response) => {
            var data = response.data;
            var message;

            if(data.success) {
                message = {
                    title: "Profile Image Updated",
                    message: "Your Profile image was successfully updated",
                    type: "success"
                };

                dispatch(updateUserProfileImage(data.profile));
            }
            else {
                message = {
                    title: "Error",
                    message: "Profile Image was not updated",
                    type: "error"
                }

                dispatch(userProfileImageUpdateFailed());
            }

            dispatch(addPopupMessage(message));
        })
        .catch((err) => {
            console.error(err);

            var message = {
                title: "Error",
                message: "At this time your profile Image could not be uploaded please try again shortly",
                type: "error"
            }

            dispatch(addPopupMessage(message));
            dispatch(userProfileImageUpdateFailed());
        });
    }
}

function userProfileImageUpdateFailed() {
    return {
        type: USER_PROFILE_IMAGE_UPDATE_FAILED
    }
}

function updateUserProfileImage(image) {
    return {
        type: USER_UPDATE_PROFILE_IMAGE,
        image: image
    }
}

function userUpdateNotificationSettingsQuery() {
    return {
        type: USER_UPDATE_NOTIFICATION_SETTINGS_QUERY
    }
}

function userUpdateNotificationSettingsQueryComplete() {
    return {
        type: USER_UPDATE_NOTIFICATION_SETTINGS
    }
}

function updateUserNotificationSettingsValues(notificationSettings) {
    return {
        type: USER_UPDATE_NOTIFICATION_SETTINGS_VALUES,
        nSettings: notificationSettings
    }
}

export function userUpdateNotificationSettings(authToken, values) {
    return function(dispatch) {
        dispatch(userUpdateNotificationSettingsQuery());

        var data = {
            token: authToken,
            form: values
        }

        apiService.post('update-user-notification-preferences', data)
        .then(function(response) {
            var message = {};
            
            if(response.data.success === true) {
                message.title = "Success";
                message.message = "Notification Preferences Successfully Updated";
                message.type = "success";
                dispatch(updateUserNotificationSettingsValues(response.data.userSettings));
            }
            else {
                message.title = "Error";
                message.message = "Notification settings could not be updated at this time";
                message.type = "error";
            }

            dispatch(addPopupMessage(message));
            dispatch(userUpdateNotificationSettingsQueryComplete())
        })
        .catch(function(error) {
            console.error(error);

            var message = {};
            message.title = "Error";
            message.message = "Notification settings could not be updated at this time";
            message.type = "error";

            dispatch(addPopupMessage(message));
            dispatch(userUpdateNotificationSettingsQueryComplete());
        })
    }
}