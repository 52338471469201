import React from 'react';
import './DashboardRank.scss';
import { useSelector } from 'react-redux';
import { addCommas } from '../Utilities/utilities';

function DashboardRank() {
    var user = useSelector(state => state.user.user);

    function rowClasses(index, rank) {
        var classes = "rankRow";
        classes += " " + rank[index]['class'];

        return classes;
    }

    return (
        <div className="DashboardRank">
            <div className="categories">
                <div>{new Date().getFullYear()} Rank</div>
                <div>Broker</div>
                <div>City</div>
                <div>Points</div>
            </div>
            {user?.rank?.map((rank, key) => 
                <div className={rowClasses(key, user.rank)} key={key}>
                    <div className="rank">
                        {rank.rank}
                    </div>
                    <div className="broker">
                        {rank.broker}
                    </div>
                    <div className="city">
                        {rank.city}
                    </div>
                    <div className="points">
                        {addCommas(rank.points)}
                    </div>
                </div>
            )}
        </div>
    );
}

export default DashboardRank;
