import "./FormSingleCheckbox.scss";
import React from 'react';
import { useRef } from "react";

function FormSingleCheckbox(props) {
    var classes = "FormSingleCheckbox FormField";
    var check = useRef();

    if(props.classes)
    classes = classes + " " + props.classes;

    if(props.checked)
    classes = classes + " " + 'checked'

    function InputChanged(e) {
        var value = e.target.checked;
        props.setFormValue({...props.formValue, [props.name]: value});
    }

    return (
        <div className={classes}>
            <div className="input-wrapper">
                <div className="box">
                    <img src="/img/check-mark.svg" alt="" className="check" />
                    <input
                        type="checkbox"
                        id={props.name}
                        name={props.name}
                        onChange={InputChanged}
                    />
                </div>
            </div>
            <label htmlFor={props.name}>
                <span className="select-heading">{props.label}</span>
                <span className="select-description">{props.descript}</span>
            </label>
        </div>
    )
}

export default FormSingleCheckbox;