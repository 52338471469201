//Content Actions
export const CONTENT_QUERY = "CONTENT:CONTENT_QUERY";
export const CONTENT_UPDATE = "CONTENT:CONTENT_UPDATE";

//User Actions
export const USER_REGISTER = "USER:USER_REGISTER";
export const USER_REGISTER_QUERY = "USER:USER_REGISTER_QUERY";
export const USER_REGISTER_ATTEMPT_FAILED = "USER:USER_REGISTER_ATTEMPT_FAILED";

export const USER_LOGIN = "USER:USER_LOGIN";
export const USER_LOGIN_QUERY = "USER:USER_LOGIN_QUERY";
export const USER_LOGIN_ATTEMPT_FAILED = "USER:USER_LOGIN_ATTEMPT_FAILED";

export const USER_VERIFY = "USER:USER_VERIFY";
export const USER_PREVERIFY_LOGIN = "USER:USER_PREVERIFY_LOGIN";
export const USER_VERIFY_QUERY = "USER:USER_VERIFY_QUERY";
export const USER_VERIFY_FAILED = "USER:USER_VERIFY_FAILED";
export const USER_PREVERIFY_FAILED = "USER:USER_PREVERIFY_FAILED";

export const USER_UPDATE_CONTENT = "USER:USER_UPDATE_CONTENT";

export const USER_NEW_EMAIL_VERIFICATION_REQUEST_STARTED = "USER:USER_NEW_EMAIL_VERIFICATION_REQUEST_STARTED";
export const USER_NEW_EMAIL_VERIFICATION_REQUEST_COMPLETE = "USER:USER_NEW_EMAIL_VERIFICATION_REQUEST_COMPLETE";

export const USER_UPDATE_NOTIFICATION_SETTINGS = "USER:USER_UPDATE_NOTIFICATION_SETTINGS";
export const USER_UPDATE_NOTIFICATION_SETTINGS_QUERY = "USER:USER_UPDATE_NOTIFICATION_SETTINGS_QUERY";
export const USER_UPDATE_NOTIFICATION_SETTINGS_VALUES = "USER:USER_UPDATE_NOTIFICATION_SETTINGS_VALUES";

export const USER_UPDATE_VALUES = "USER:USER_UPDATE_VALUES";
export const USER_UPDATE_VALUES_QUERY = "USER:USER_UPDATE_VALUES_QUERY";
export const USER_PROFILE_SUBMIT_ATTEMPT_FAILED = "USER:USER_PROFILE_SUBMIT_ATTEMPT_FAILED";

export const USER_UPDATE_PROFILE_IMAGE = "USER:USER_UPDATE_PROFILE_IMAGE";
export const USER_PROFILE_IMAGE_UPDATE_FAILED = "USER:USER_PROFILE_IMAGE_UPDATE_FAILED";
export const USER_UPDATE_PROFILE_IMAGE_QUERY = "USER:USER_UPDATE_PROFILE_IMAGE_QUERY";

export const USER_CHANGE_PASSWORD = "USER:USER_CHANGE_PASSWORD";
export const USER_CHANGE_PASSWORD_QUERY = "USER:USER_CAHNGE_PASSWORD_QUERY";

export const USER_LOGOUT = "USER:USER_LOGOUT";

//Popup Actions
export const POPUP_ADD_MESSAGE = "POPUP:POPUP_ADD_MESSAGE";
export const POPUP_GET_NEXT_MESSAGE = "POPUP:POPUP_GET_NEXT_MESSAGE";
export const POPUP_CLOSE_POPUP = "POPUP:POPUP_CLOSE_POPUP";
