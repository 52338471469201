import "./Form.scss";
import React, { useState } from 'react';
import FormHtmlField from './FormHtmlField';
import FormTextField from './FormTextField';
import FormSubmitField from "./FormSubmitField";
import FormSingleCheckbox from "./FormSingleCheckbox";
import FormFileField from "./FormFileField";
import { useEffect } from "react";

function Form(props) {
    //acf form should be in props and should have the output from the form
    //"plugin"
    const [formValue, setFormValue] = useState(props.preValues ? props.preValues : {});
    var [formError, setFormError] = useState({});

    //this is to start up the form
    //if we have prevalues we will prepopulate the formValues

    var classes = "Form";

    if(props.classes)
    classes = classes + "  " + props.classes;

    function Submit(e) {
        e.preventDefault();

        if(!props.submitting) {
            //validate
            var validated = defaultFormValidation();

            if(props.validate)
            validated = props.validate(formValue, formError);
            
            if(validated)
            if(props.onSubmit)
            props.onSubmit(formValue);
        }
    }

    function defaultFormValidation() {
        var valid = true;

        var errors = Object.assign({}, formError);
        props.fields.map((field, key) => {
            if(!validateFieldByType(field)) {
                errors[field.field_name] = true;
                valid = false;
            }
            else {
                errors[field.field_name] = false;
            }
        });

        setFormError(errors);
        return valid;
    }

    function validateFieldByType(field, key) {
        var valid = true;

        if(field.required) {
            switch(field.acf_fc_layout) {
                case "text_field":
                    if(!formValue[field.field_name]) valid = false;
                break;
            }
        }

        return valid;
    }

    function getFormFieldComponentByType(field, key) {
        switch(field.acf_fc_layout) {
            case "html":
                return <FormHtmlField HTML={field.code} key={key} />
            case "text_field":
                return (
                    <FormTextField 
                        key={key}
                        name={field.field_name}
                        label={field.label_text}
                        placeholder={field.placeholder_text}
                        error={
                            formError[field.field_name]
                        }
                        type={field.type}
                        icon={field.icon}
                        errorText={field.error_text}
                        required={field.required}
                        classes={field.css_classes}
                        formValue={formValue}
                        setFormValue={setFormValue}
                        errorValue={formError}
                        setErrorValue={setFormError}
                    />
                );
            case "single_checkbox":
                return (
                    <FormSingleCheckbox
                        key={key}
                        label={field.label_text}
                        classes={field.css_classes}
                        error={formError[field.field_name]}
                        descript={field.email_label}
                        errorText={field.error_text}
                        name={field.field_name}
                        formValue={formValue}
                        checked={formValue[field.field_name]}
                        setFormValue={setFormValue}
                        errorValue={formError}
                        setErrorValue={setFormError}
                    />
                )
            case "file":
                return (
                    <FormFileField 
                        key={key}
                        label="Test Label"
                    />
                )
                
            case "submit_button":
                return (
                    <FormSubmitField
                        key={key}
                        label={field.button_text}
                        classes={field.css_classes}
                        errorText={field.button_text}
                        width={field.width}
                        icon={field.icon}
                        submitting={props.submitting}
                    />
                );
            default:
                break;
        }
    }
    
    return(
        <form className={classes} onSubmit={Submit}>
            {/*
                Go through all of the fields we have for hte form and add
                the correct form field component
             */}
             {props.fields.map((field, key) => 
                getFormFieldComponentByType(field, key)
             )}
        </form>
    )
}

export default Form;