import axios from 'axios';

//let baseUrl = 'http://local.marlinloyalty.com/wp-json/52ascentapi/v1/';
let baseUrl = 'http://localhost/wp-json/52ascentapi/v1/';


if(window.location.host === "ascent.52beta.ca")
    baseUrl = 'https://ascent-backend.52beta.ca/wp-json/52ascentapi/v1/';
if(window.location.host === "ascent.marlinspring.com")
    baseUrl = 'https://ascent-backend.marlinspring.com/wp-json/52ascentapi/v1/';

function get(url, data = {}) {
    return axios({
        headers: {
            'Content-type': 'application/json'
        },
        method: 'get',
        url: baseUrl+url,
        params: data
    });
}

function post(url, data = {}) {
    return axios({
        headers: {
            'Content-type': 'application/json'
        },
        method: 'post',
        url: baseUrl+url,
        data: data
    });
}

function getContent() {
    return get('get-all-content');
}

function getPosts(type) {
    return get('getposts', { "type": type });
}

function getFloorplans() {
    return get('getfloorplans');
}

function submitForm(data) {
    return post('submit-form', data);
}

export default {
    get,
    post,
    getPosts,
    submitForm,
    getFloorplans,
    getContent
};