import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import "./SmallUserProfile.scss";

function SmallUserProfile(props) {    
    var user = useSelector(state => state.user.user);
    var tiers = useSelector(state => state.content.options.tiers);
    var classes = "SmallUserProfile";
    if(props.location.pathname.includes('/profile')) classes += " active";

    if(tiers && user) {
        var currentTier = findCurrentTierByPoints();

        if(currentTier+1 < tiers.length)
        var toNextTier = tiers[currentTier+1].lowend - user.points;
    }

    function findCurrentTierByPoints() {
        for(var i = 0; i < tiers.length; i++) {
            var tier = tiers[i];

            if(tier.name == user.tier) 
                return i;
        }
        
        return 0;
    }

    var profileImage = user?.profile ?
        user?.profile :
        {url: "/img/default-profile-picture.svg", alt: "Profile Image"};

    return (
        <Link to="/profile/details" className={classes}>
            <div>
                <div className="image-wrapper">
                    <img
                        className="profile-picture"
                        src={profileImage.url}
                        alt={profileImage.alt}
                    />
                </div>
            </div>
            <div className="info-container">
                <label className="username">{`${user?.firstname} ${user?.lastname?.charAt(0)}.`}</label>
                <label className="tier">{tiers && tiers[currentTier].name}&nbsp;Tier</label>
            </div>
        </Link>
    );
}

export default SmallUserProfile;