import "./FormReqStar.scss";
import React from 'react';

function FormReqStar(props) {
    return(
        <span className="FormReqStar">
            {!props.nospace && <span>&nbsp;</span>}*
        </span>
    );
}

export default FormReqStar;