import React, { useEffect } from 'react';
import LoginLeft from '../LoginLeft/LoginLeft';
import LoginRight from '../LoginRight/LoginRight';
import './Home.scss';

function Home() {
    var homeStyles = {
        background: 'url(/img/homepage-bg.jpg)'
    };

    useEffect(() => {
        var container = document.querySelector(".router-content-wrapper");
        container.classList.add('home');
        return () => container.classList.remove('home');
    });

    return (
        <div className="Home" style={homeStyles}>
            <LoginLeft />
            <LoginRight />
        </div>
    );
}

export default Home;
