import React from 'react';
import { useSelector } from 'react-redux';
import Row from '../Row/Row';
import Col from '../Col/Col';
import LeftSidebar from '../LeftSidebar/LeftSidebar';
import ContentLightBlueWrapper from '../ContentLightBlueWrapper/ContentLightBlueWrapper';
import './TierDetails.scss';

function TierDetails() {
	const content = useSelector((state) => state.content.pages?.tier_details);

    return (
        <div className="TierDetails">
            <LeftSidebar />
            <ContentLightBlueWrapper>
                <div className="heading-wrapper">
                    <h1>{content?.post_title}</h1>
                    <span className="disclaimer">
                        <div>
                            {content?.disclaimer}
                        </div>
                    </span>
                </div>

                {/* make this its own component */}
                <div className="mobile-disclaimer-container">
                    <span className="mobile-disclaimer">
                        Swipe Left
                    </span>
                </div>
                <div className="details-container">
                    <div className="top">
                        Tier
                    </div>
                    <div className="top">
                        Eligibility
                    </div>
                    <div className="top">
                        Rewards
                    </div>
                    <div className="top">
                        Next Tier
                    </div>
                    {content?.tiers.map((tier, key) => (
                        <React.Fragment key={key}>
                            <div className={`tier${key === 0 ? " first" : ""}${key+1 === content.tiers.length ? " last " : ""}`}>
                                <img src={tier.image.url} alt={tier.image.alt} className="icon" />
                                {tier.tier}
                            </div>
                            <div
                                className={`eligibility${key === 0 ? " first" : ""}${key+1 === content.tiers.length ? " last " : ""}`}
                                dangerouslySetInnerHTML={{__html: tier.eligibility}}
                            />
                            <div className={`rewards${!tier.next_tier ? ' wide' : ''}${key === 0 ? " first" : ""}${key+1 === content.tiers.length ? " last " : ""}`}>
                                {tier.rewards}
                            </div>
                            {tier.next_tier &&
                                <div
                                    className={`next${key === 0 ? " first" : ""}${key+1 === content.tiers.length ? " last " : ""}`}
                                    dangerouslySetInnerHTML={{__html: tier.next_tier}}
                                />
                            }
                        </React.Fragment>
                    ))}
                </div>
            </ContentLightBlueWrapper>
        </div>
    );
}

export default TierDetails;
