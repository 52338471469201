import "./ProfileSecurity.scss";
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import ProfileSecurityForm from "./ProfileSecurityForm";
import FormReqStar from "../Form/FormReqStar";
import ProfileBackSection from "../Profile/ProfileBackSection";

function ProfileSecurity() {
    var [width, setWidth] = useState(window.innerWidth);

    var pageContent = useSelector(
        state => state.content.pages.profile_security
    );

    function updateSize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', updateSize);

        return () => {
            window.removeEventListener('resize', updateSize);
        }
    })

    return(
        <div className="ProfileSecurity">
            <div className="icon-wrapper">
                {width < 481 && 
                    <ProfileBackSection image="/img/small-security-lock.svg" />
                }
                {width > 480 && 
                    <img src="/img/profile-security-lock.svg" alt="" className="lock" />
                }
            </div>
            <div className="form-wrapper">
                {pageContent?.form &&
                    <ProfileSecurityForm form={pageContent.form} />
                }
            </div>
            <div className="required-fields">
                <FormReqStar nospace />&nbsp;Required
            </div>
        </div>
    )
}

export default ProfileSecurity;