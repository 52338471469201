import './UserLogout.scss';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { userLogout } from '../../redux/actions/userActions';
import LogoutIcon from './LogoutIcon';

function UserLogout() {
    var dispatch = useDispatch();
    var history = useHistory();

    function handleClick() {
        dispatch(userLogout());
        history.push('/');
    }

    return(
        <div className="UserLogout" onClick={handleClick}>
            <LogoutIcon />
            Logout
        </div>
    )
}

export default UserLogout;