import React from 'react';
import { useSelector } from 'react-redux';
import './LeftSidebar.scss';
import { Link, useLocation } from 'react-router-dom';
import SmallUserProfile from '../SmallUserProfile/SmallUserProfile';
import LeftSideBarMainNav from '../LeftSideBarMainNav/LeftSideBarMainNav';
import LeftSideBarSubNav from '../LeftSideBarSubNav/LeftSideBarSubNav';
import LeftSideBarPrivacyNav from '../LeftSideBarPrivacyNav/LeftSideBarPrivacyNav';
import MarlinSpringLogo from '../MarlinSpringLogo/MarlinSpringLogo';

function LeftSidebar() {
    var loggedIn = useSelector(state => state.user.userLoggedIn);
    var verified = useSelector(state => state.user.userVerified);
    var confirmed = useSelector(state => state.user.userConfirmed);

    var styles = {backgroundImage: "url(img/left-side-bar-bg-colour.jpg)"};
    var location = useLocation();


    return (
        <React.Fragment>
            <div className="height-spacer" />
            <div className="LeftSidebar" style={styles}>
                <Link to="/" className="logo">
                    <MarlinSpringLogo />
                    <span className="app-name">
                        Ascent<br />Program
                    </span>
                </Link>
                {loggedIn === true &&  verified === true && confirmed === true &&
                    <React.Fragment>
                        <LeftSideBarMainNav location={location} />
                        <LeftSideBarSubNav location={location} />
                        <LeftSideBarPrivacyNav location={location} />
                        <SmallUserProfile location={location} />
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    );
}

export default LeftSidebar;