// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import apiService from '../../services/apiService';
import cookieService from "../../services/cookieService";
import { addPopupMessage } from "../../redux/actions/popupActions";
import { dispatchPopupMessage } from "../../services/popupService";
import { updateUserContent } from "../../redux/actions/userActions";

const firebaseVapidKey = 'BEzDXVLAw8HvxgDgzMr_niOIubxD85MG9B5TlEJsARotLAwwLJI3t6SyvaBYrbkCOq0gMzRTISllmhnFePbB0Po';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDsdq3LLXB1ItDoj73UN5oked7T6t8LHxk",
  authDomain: "marlin-spring-ascent.firebaseapp.com",
  projectId: "marlin-spring-ascent",
  storageBucket: "marlin-spring-ascent.appspot.com",
  messagingSenderId: "1067094614622",
  appId: "1:1067094614622:web:0826d9ea933a6ee050cacf",
  measurementId: "G-L3WDPCR2N8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

onMessage(messaging, (payload) => {
    // ...

    var message = {
        title: payload.data.title,
        message: decodeURIComponent(payload.data.msg.replace(/\+/g, ' '))
    };

    dispatchPopupMessage(addPopupMessage(message));

	//this function should have been named global dispatch
	dispatchPopupMessage(updateUserContent());
});

export const askForPermissionToReceiveNotifications = (setBarHidden) => {
	return getToken(messaging, { vapidKey: firebaseVapidKey }).then((currentToken) => {
	  if (currentToken) {
	  	console.log(currentToken);
	  	console.log('allowed');
	  	setBarHidden(true);
	    // Send the token to your server and update the UI if necessary
        var user = cookieService.getCookie('asc_user');
        user = JSON.parse(user);

        apiService.post('user-set-notification-token', {token: currentToken, userToken: user.token})
	        .catch(function(err) {
	            console.error(err);
        });

	  } else {
	    // Show permission request UI
	    //console.log('No registration token available. Request permission to generate one.');
	    //console.log('show: ' + Notification.permission);
	    setBarHidden(false);
	  }
	}).catch((err) => {
	  //console.log('An error occurred while retrieving token. ', err);
	  console.log('notification permission: ' + Notification.permission);
	  // if we get error it means they cancelled notification
	  if (Notification.permission == "default") {
	  	setBarHidden(false);
	  } else {
	  	setBarHidden(true);
	  }
	});
}
