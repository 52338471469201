import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/* Redux imports */
import thunk from 'redux-thunk';
import reducer from './redux/reducers';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { setStore } from './services/popupService';

let store;
//creates the redux store using the combined reducers and applies the thunk middleware for async
if(window.location.host === "ascent.52beta.ca" || window.location.host === "localhost:3000") {
    store = createStore(
        reducer,
        compose(
            applyMiddleware(thunk),
            window.__REDUX_DEVTOOLS_EXTENSION__
                ? window.__REDUX_DEVTOOLS_EXTENSION__()
                : (f) => f
        )
    );
}
else {
    store = createStore(
        reducer,
        compose(applyMiddleware(thunk))
    );
}

setStore(store);

ReactDOM.render(
  <React.StrictMode>
  	<Provider store={store}>
    	<App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();