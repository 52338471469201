import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LeftSidebar from '../LeftSidebar/LeftSidebar';
import LeftNotificationSidebar from '../LeftNotificationSidebar/LeftNotificationSidebar';
import './Notifications.scss';
import ArrowLink from '../ArrowLink/ArrowLink';
import { useLocation } from "react-router-dom";

function Notifications(props) {
	const [index, setIndex] = useState(0);
	//<ChildComponent
    var user = useSelector(state => state.user.user);

  	const location = useLocation();

	useEffect(() => {
	  	// if we have an ID passed
	  	var notificationURLID = location.pathname.replace('/notifications/','');
		// if we have a valid number, then load that notification by setIndex
		if (!isNaN(notificationURLID)) {
			//console.log(notificationURLID);
			setIndex(notificationURLID);
		}
	}, []);

    var [open, setOpen] = useState(false);
    var [winWidth, setWinWidth] = useState(window.innerWidth);

    var mobileOpen = open;

    if(winWidth > 899) {
        mobileOpen = true;
    }

    var openStyle = {
        display: mobileOpen ? "block" : "none"
    };

	function time_ago(time) {
	  var dateObj = new Date(time.replace(/-/g, "/"));
	  dateObj.setMinutes(dateObj.getMinutes() - dateObj.getTimezoneOffset());
	  time = dateObj.getTime();

	  // if we dont have valid value return empty string
	  if (typeof time === 'undefined' || time == null) return "";

	  switch (typeof time) {
	    case 'number':
	      break;
	    case 'string':
	      time = +new Date(time);
	      break;
	    case 'object':
	      if (time.constructor === Date) time = time.getTime();
	      break;
	    default:
	      time = +new Date();
	  }
	  var time_formats = [
	    [60, 'seconds', 1], // 60
	    [120, '1 minute ago', '1 minute from now'], // 60*2
	    [3600, 'minutes', 60], // 60*60, 60
	    [7200, '1 hour ago', '1 hour from now'], // 60*60*2
	    [86400, 'hours', 3600], // 60*60*24, 60*60
	    [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
	    [604800, 'days', 86400], // 60*60*24*7, 60*60*24
	    [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
	    [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
	    [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
	    [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
	    [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
	    [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
	    [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
	    [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
	  ];
	  var seconds = (+new Date() - time) / 1000,
	    token = 'ago',
	    list_choice = 1;

	  if (seconds == 0) {
	    return 'Just now'
	  }
	  if (seconds < 0) {
	    seconds = Math.abs(seconds);
	    token = 'from now';
	    list_choice = 2;
	  }
	  var i = 0,
	    format;
	  while (format = time_formats[i++])
	    if (seconds < format[0]) {
	      if (typeof format[2] === 'string')
	        return format[list_choice];
	      else
	        return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
	    }
	  return time;
	}

	function getNotificationIndex(id) {
		if (user?.notifications?.length > 0) {
			for (var i=0; i < user.notifications.length; i++) {
				if (user.notifications[i].id == id) {
					return i;
				}
			}
		}
		return -1;
	}

	function getIcon(subType) {
		if (typeof (subType) != 'undefined' && subType != '' && typeof (subType) != 'null') {
			return (
				<img src={'/img/notification-icons/' + subType + '_icon.svg'} className="notification-icon" />
			)
		}
	}

	function getNotificationCopy(nCopy) {
		var email = "sales@marlinspring.com";
		//search the copy for the email. If we find it we want to replace it with an a tag mail to link
		//we also need to change the way this is rended out to be innerHTML

		var replaceString = `<a href="mailto:${email}">${email}</a>`;
		var newCopy = nCopy.replace(/sales@marlinspring.com/g, replaceString);
		return newCopy;
	}

    function getRightContent() {
    	if (user?.notifications?.length > 0) {
    		var notificationIndex = getNotificationIndex(index)
			if(notificationIndex !== -1) {
				return (
					<div>
						<div className="close-link" onClick={() => setMobileActive(false)}>
							<img src="/img/back-arrow-arrow.svg"  alt="" />
							<span>Back to Notifications</span>
						</div>
						<p className="date">{time_ago(user?.notifications[notificationIndex]?.created_at)}</p>
						<h1>{getIcon(user?.notifications[notificationIndex]?.subtype)}{user?.notifications[notificationIndex]?.title}</h1>
						<p className="copy" dangerouslySetInnerHTML={{__html: getNotificationCopy(user?.notifications[notificationIndex]?.copy)}} />
						{user?.notifications[notificationIndex]?.cta && user?.notifications[notificationIndex]?.cta_label &&
							<ArrowLink
								to={user?.notifications[notificationIndex]?.cta}
								label={user?.notifications[notificationIndex]?.cta_label}
								black
							/>
						}
					</div>
				);
			}
	    	else {
				return (
					<div></div>
				)
			}
    	} else {
    		return (
    			<div></div>
    		)
    	}
    }

    function updateWinSize() {
        setWinWidth(window.innerWidth);
    }

    function setMobileActive(active) {
        if(winWidth < 900) {
            setOpen(active);
        }
    }

    useEffect(() => {
        window.addEventListener('resize', updateWinSize);
        return () => window.removeEventListener('resize', updateWinSize);
    });

    return (
        <div className="Notifications">
            <LeftSidebar />
            <LeftNotificationSidebar index={index} setIndex={setIndex} time_ago={time_ago} setMobileActive={setMobileActive} />
            <div className="notifications-content" style={openStyle}>
                    {getRightContent()}
            </div>
        </div>
    );
}

export default Notifications;
