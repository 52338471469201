import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

function UserLoggedIn(props) {
    //check if the user is loggedin
    //if not move back to login
    var history = useHistory(); 

    var loggedIn = useSelector(state => state.user.userLoggedIn);
    var verified = useSelector(state => state.user.userVerified);
    var confirm = useSelector(state => state.user.userConfirmed);
    var preverified = useSelector(state => state.user.userPreVerified);
    
    if(preverified == true) {
        if(loggedIn === true) {
            if(verified !== true) 
            history.push('/account-created');
            else if(confirm !== true)
            history.push('/account-verified');
        }
        if(loggedIn !== true) history.push('/');
    }
    if(!loggedIn && !verified && !confirm && !preverified) history.push('/');

    return(
        <div
            style={{display:'none'}}
        />
    );
}

export default UserLoggedIn;