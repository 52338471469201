import "./ProfileUploadModal.scss";
import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { requestProfileImageUpdate } from "../../redux/actions/userActions";
import FormSubmitField from "../Form/FormSubmitField";

function ProfileUploadModal(props) {
    var classes = "ProfileUploadModal";
    var fileInput = useRef();
    var preview = useRef();
    var authToken = useSelector(state => state.user.authToken);
    var dispatch = useDispatch();
    var [preview, setPreview] = useState("");
    var [error, setError] = useState(false);
    var submitting = useSelector(state => state.user.userCanUpdateProfileImage);

    if(props.open) classes += " open";

    var errorClasses = "error";
    if(error === true) errorClasses += " show";

    function changed() {
        setPreview(URL.createObjectURL(fileInput.current.files[0]));
        
        setError(fileInput.current.files.length < 1);
    }

    function uploadNewImage(event) {
        event.preventDefault();
        if(fileInput.current.files.length > 0) {
            var formData = new FormData();
            formData.append('profile', fileInput.current.files[0]);
            formData.append('token', authToken);

            dispatch(requestProfileImageUpdate(formData));
        } else {
            setError(true);
        }
    }

    return(
        <div className={classes} onClick={props.close}>
            <div className="inner-wrapper" onClick={(e) => {e.stopPropagation();}}>
                <div className="close-button" onClick={props.close}>
                    <span></span><span></span>
                </div>
                <h2>User Profile Image Settings</h2>
                <div>
                    <div className="wrapper">
                        {preview !== "" &&
                            <img src={preview} alt="" width="96" height="96" />
                        }
                    </div>
                </div>
                <form action="POST" onSubmit={uploadNewImage} name="profileUploadForm">
                    <div className="file-wrapper">
                        <label htmlFor="fileinput">
                            Upload File
                        </label>
                        <input
                            name="fileinput"
                            ref={fileInput}
                            type="file"
                            onChange={changed}
                            accept="image/png, image/jpeg, image/svg"
                        />
                        <label className={errorClasses}>
                            Please select a file
                        </label>
                    </div>
                    <FormSubmitField
                        label="Upload"
                        classes=""
                        errorText=""
                        submitting={!submitting}
                    />
                </form>
            </div>
        </div>
    )
}

export default ProfileUploadModal;