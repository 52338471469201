import {
    POPUP_ADD_MESSAGE,
    POPUP_GET_NEXT_MESSAGE,
    POPUP_CLOSE_POPUP
} from '../actions/actionTypes';
import Queue from '../../utilities/Queue';

const initalState = {
    queue: new Queue(),
    open: false,
    queueLength: 0,
    current: null
};

function PopupReducer(state = initalState, action) {
    switch(action.type) {
        case POPUP_ADD_MESSAGE:
            var popup = Object.assign({}, state);

            if(popup.current === null) {
                popup.current = action.message;
                popup.open = true;
            }
            else {
                popup.queue.enqueue(action.message);
                popup.queueLength = popup.queue.length();
            }
                
            return popup;
        case POPUP_GET_NEXT_MESSAGE:
            var popup = Object.assign({}, state);

            popup.current = popup.queue.dequeue();
            popup.queueLength = popup.queue.length();
            
            if(popup.current !== null) popup.open = true;
            else popup.open = false;

            return popup;
        case POPUP_CLOSE_POPUP:
            var popup = Object.assign({}, state);
            popup.open = false;

            return popup;
        default:
            return state;
    }
}

export default PopupReducer;