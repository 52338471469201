import React from 'react';

function DashboardIcon(props) {
    return(
        <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path
                    d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM5 19V5h6v14H5zm14 0h-6v-7h6v7zm0-9h-6V5h6v5z"
                    fill="#9AABB8"
                    fillRule="nonzero"
                    className="fill-path"
                />
            </g>
        </svg>
    )
}

export default DashboardIcon;