import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Row from '../Row/Row';
import Col from '../Col/Col';
import LeftSidebar from '../LeftSidebar/LeftSidebar';
import LeftSidebarLight from '../LeftSidebarLight/LeftSidebarLight';
import './CreateNewPassword.scss';

function CreateNewPassword() {
	const content = useSelector((state) => state.content.pages?.create_new_password);

    return <div className="CreateNewPassword">
				<Row>
					<LeftSidebar />
					<LeftSidebarLight>
						<h1>{content?.title}</h1>
						<p>{content?.intro_copy}</p>
					</LeftSidebarLight>
					<Col className="large-7 columns">
						<Link to="/">
							<img src="/img/fpo-create-new-password.png" alt="" />
						</Link>
					</Col>
				</Row>
    		</div>;
}

export default CreateNewPassword;
