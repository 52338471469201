import "./ProfileDetails.scss";
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import ProfileDetailsForm from './ProfileDetailsForm';
import ProfileBackSection from "../Profile/ProfileBackSection";
import FormReqStar from "../Form/FormReqStar";

function ProfileDetails() {
    var [width, setWidth] = useState(window.innerWidth);

    var pageContent = useSelector(
        state => state.content.pages.profile_details
    );

    function updateSize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', updateSize);

        return () => {
            window.removeEventListener('resize', updateSize);
        }
    });

    return (
        <div className="ProfileDetails">
            
            {width < 900 &&
                <ProfileBackSection image="/img/small-profile-image.svg" />
            }
            {width > 899 && 
                <div className="image-container">
                    <img src="/img/large-profile-image.svg" alt="" />
                </div>
            }
            <div className="form-wrapper">
                {pageContent?.form &&
                    <ProfileDetailsForm form={pageContent.form} />
                }
            </div>
            <div className="required-fields">
                <FormReqStar nospace />&nbsp;Required
            </div>
        </div>
    );
}

export default ProfileDetails;