import React, { useState } from 'react';
import LeftSidebar from '../LeftSidebar/LeftSidebar';
import Incentive from '../Incentive/Incentive';
import './Incentives.scss';
import { useSelector } from 'react-redux';
import IncentiveFilter from '../IncentiveFilter/IncentiveFilter';

function Incentives() {
    var incentives = useSelector(state => state.content.options.incentives);
    var [filterValue, setFilterValue] = useState("");
    var content = useSelector(state => state.content.pages.incentives);

    var projects = useSelector(state => state.content.options.development_images);
    var projectNames = useSelector(state => state.content.options.projects_select); 
    var projectCities = [];

    var filteredIncentives = [];
    var displayIncentives = [];

    var manualProjects = useSelector(state => state.content.options.manual_projects);
    var manualIncents = [];

    var filterItems = [];

    if(manualProjects) {
        for(var i = 0; i < manualProjects.length; i++) {
            for(var j = 0; j < manualProjects[i].incentives.length; j++) {
                var incent = Object.assign({}, manualProjects[i].incentives[j]);
                incent.project_name = manualProjects[i].project_name;
                incent.location = manualProjects[i].location;
                incent.project_image = manualProjects[i].project_image;
                incent.manual = true;
                manualIncents.push(incent);
            }
        }
    }

    //lets just go ahead and ifgure out how to do this.
    if(filterValue !== "") {
        for(var i = 0; i < incentives.length; i++) {
            var proj;

            for(var j = 0; j < projects.length; j++) {
                if(incentives[i].project.id == projects[j].project_id) {
                    proj = projects[j];
                } 
            }
            
            if(proj.location == filterValue) {
                filteredIncentives.push(incentives[i]);
            }
        }
        for(var i = 0; i < manualIncents.length; i++) {
            if(manualIncents[i].location == filterValue)
            filteredIncentives.push(manualIncents[i]);
        }
    }
    else {
        filteredIncentives = [].concat(incentives, manualIncents)
    }

    for(var i = 0; i < filteredIncentives.length; i++) {
        if(filteredIncentives[i].units.length > 0) {
            displayIncentives.push(filteredIncentives[i]);
        }
    }

    if(projects) {
        for(var i = 0; i < projects.length; i++) {

            var filterItem = {
                value: projects[i].location,
                label: projects[i].location
            };

            filterItems.push(filterItem);
        }
    }

    for(var i = 0; i < manualProjects.length; i++) {
        var addCity = true;

        for(var j = 0; j < filterItems.length; j++) {
            if(manualProjects[i].location === filterItems[j].value)
            addCity = false;
        }
        
        if(addCity) {
            var option = {
                value: manualProjects[i].location,
                label: manualProjects[i].location
            };
    
            filterItems.push(option);
        }
    }

    function returnListComponent(item, key) {
        if(typeof item.manual !== 'undefined') {
            return (
                <Incentive
                    key={key}
                    points={item.points}
                    projectLocation={item.location}
                    projectImage={{project_image: item.project_image}}
                    projectName={item.project_name}
                    units={item.units}
                    manual
                />
            );
        }

        return (
            <Incentive
                key={key}
                project={item.project.id}
                points={item.points}
                projectName={item.project.name}
                units={item.units}
            />
        );
    }

    return (
        <div className="Incentives">
            <LeftSidebar />
            <div className="content-container">
                <div className="top-wrapper">
                    <h2 className="heading">
                        Incentives
                    </h2>
                    <div className="spacer">&nbsp;</div>
                    <IncentiveFilter
                        label="Select Location"
                        options={filterItems}
                        update={setFilterValue}
                    />
                </div>
                <div className="incentive-container">
                    {displayIncentives && displayIncentives.map((incentive, key) => 
                        returnListComponent(incentive, key)
                    )}
                </div>
                {displayIncentives.length === 0 && 
                    <div className="no-incentives">
                        <img src={content.no_incentives_icon.url} alt={content.no_incentives_icon.alt} />
                        <div className="text">
                            {content.no_incentives_message}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default Incentives;
