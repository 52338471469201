import './LargeUserProfile.scss';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ProfileUploadModal from './ProfileUploadModal';

function LargeUserProfile() {
    var user = useSelector(state => state.user.user);
    var tiers = useSelector(state => state.content.options.tiers);
    var [modalOpen, setModalOpen] = useState(false);

    if(tiers && user) {
        var currentTier = findCurrentTierByPoints();
    }

    function findCurrentTierByPoints() {
        for(var i = 0; i < tiers.length; i++) {
            var tier = tiers[i];

            if(tier.name == user.tier) 
                return i;
        }
        
        return 0;
    }

    function toggleProfileUploadModal() {
        setModalOpen(!modalOpen);
    }

    var profileImage = user?.profile ?
        user?.profile :
        {url: "/img/default-profile-picture.svg", alt: "Profile Image"};

    return (
        <div className="LargeUserProfile">
            <div>
                <div className="img-wrapper">
                    <img
                        className="profile-picture"
                        src={profileImage.url}
                        alt={profileImage.alt}
                    />
                </div>
            </div>
            <div className="info-container">
                <label className="username">{user?.username}</label>
                <label className="tier">
                    {tiers ? tiers[currentTier].name : ""}
                    &nbsp;Tier
                </label>
                <div className="upload-new-picture" onClick={toggleProfileUploadModal}>
                    Upload New Photo
                </div>
            </div>
            <ProfileUploadModal open={modalOpen} close={toggleProfileUploadModal} />
        </div>
    );
}

export default LargeUserProfile;