import React from 'react';
import './LeftSidebarLight.scss';

function LeftSidebarLight(props) {
    return (
        <div className="LeftSidebarLight">
            {props.children}
        </div>
    );
}

export default LeftSidebarLight;


