import React from "react";
import * as ReactDOM from 'react-dom'

const calculateBoundingBoxes = (children, scrollPosition) => {
    const boundingBoxes = {};

    React.Children.forEach(children, child => {
        if (child !== null) {
            const domNode = child.ref.current;
            if(domNode !== null) {
                const nodeBoundingBox = domNode.getBoundingClientRect();
                const nodeBoundingBox2 = {'top': nodeBoundingBox.top + scrollPosition, 'left': nodeBoundingBox.left, 'height': nodeBoundingBox.height};
                //const el = document.querySelector("#LeftNotificationSidebar");
                //const nodeBoundingBox2 = {'top': nodeBoundingBox.top + el.scrollTop}; 

                boundingBoxes[child.key] = nodeBoundingBox2;
            }
        }
    })

    return boundingBoxes;
};

export default calculateBoundingBoxes;