import "./DashboardPointsSummary.scss";
import ContentLightBlueWrapper from "../ContentLightBlueWrapper/ContentLightBlueWrapper";
import LeftSidebar from "../LeftSidebar/LeftSidebar";
import PointsSummary from "../PointsSummary/PointsSummary";
import React from 'react';

function DashboardPointsSummary() {

    return (
        <div className="DashboardPointsSummary">
            <LeftSidebar />
            <ContentLightBlueWrapper>
                <div className="points-wrapper">
                    <h2>My Earnings Summary</h2>
                    <PointsSummary />
                </div>
            </ContentLightBlueWrapper>
        </div>
    )
}

export default DashboardPointsSummary