import "./PortfolioUpcomingProject.scss";
import React from "react";

function PortfolioUpcomingProject(props) {
    
    return(
        <div className="PortfolioUpcomingProject">
            <div className="image-wrapper">
                <img src={props?.proj?.image?.url} alt={props?.proj?.image?.alt} />
            </div>
            <div className="info-container">
                <div className="tag">
                    <span>
                        Coming Soon
                    </span>
                </div>
                <div className="heading">
                    {props?.proj?.title}
                </div>
                <a className="register" href={props?.proj?.register_link} target="_blank">
                    Register Now
                </a>
            </div>
        </div>
    )
}

export default PortfolioUpcomingProject;