import "./FormSubmitField.scss";
import React, { useEffect, useState } from 'react';

function FormSubmitField(props) {
    var [width, setWidth] = useState(window.innerWidth);

    var classes = "FormSubmitField FormField ";
    var styles = {width: props.width ? props.width : ""};

    if(width < 481) {
        styles.width = "100%";
    }

    if(props.classes)
    classes = classes + " "  + props.classes;

    if(props.submitting === true)
    classes = classes + " submitting";

    function adjustSize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', adjustSize);

        return () => {
            window.removeEventListener('resize', adjustSize);
        }
    });

    return (
        <div className={classes}>
            {props.icon && 
                <img src={props.icon} className="icon" />
            }
            <div className="submit-wrapper" style={styles}>
                <input type="submit" className="input" value={props.label } style={styles} />
            </div>
        </div>
    );
}

export default FormSubmitField;