import React from 'react';

function PortfolioIcon(props) {

    return(
        <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <g fill="#9AABB8" fillRule="nonzero" className="fill-path">
                    <path d="M17 7h2v2h-2zM17 11h2v2h-2zM17 15h2v2h-2zM1 11v10h6v-5h2v5h6V11L8 6l-7 5zm12 8h-2v-5H5v5H3v-7l5-3.5 5 3.5v7z" />
                    <path d="M10 3v1.97l2 1.43V5h9v14h-4v2h6V3z" />
                </g>
            </g>
        </svg>
    )
}

export default PortfolioIcon;