import "./MobileUserProfile.scss";
import React from 'react';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router";

function MobileUserProfile(props) {
    var user = useSelector(state => state.user.user);
    var location = useLocation();
    var classes = "MobileUserProfile";
    if(location.pathname.includes('/profile')) classes += " active";
    var profileImage = user?.profile ?
        user?.profile :
        {url: "/img/default-profile-picture.svg", alt: "Profile Image"};

    return (
        <Link to="/profile" className={classes}>
            <div className="image-wrapper">
                <img
                    className="profile-picture"
                    src={profileImage.url}
                    alt={profileImage.alt}
                />
            </div>
        </Link>
    );
}

export default MobileUserProfile;