import React, { useState, useEffect } from 'react';
import './DashboardSummary.scss';
import { useSelector } from 'react-redux';
import ArrowLink from '../ArrowLink/ArrowLink';
import { addCommas } from '../Utilities/utilities';

function DashboardSummary() {
    //var [pointsCounter, setPointsCounter] = useState(0);

    var [pointsCounters, setPointsCounters] = useState({
        main: 0,
        nextTier: 0,
        yearly: 0
    });

    var [width, setWidth] = useState(window.innerWidth);
    var user = useSelector(state => state.user.user);
    var tiers = useSelector(state => state.content.options.tiers);

    var duration = 1600 * (getUserTier() + 1);
    var update = 1000 / 60;
    var percent = update / duration;

    if(tiers && user) {
        var currentTier = findCurrentTierByPoints();

        if(currentTier+1 < tiers.length)
        var toNextTier = tiers[currentTier+1].lowend - user.points;
        if(toNextTier < 0) toNextTier = 0;
    }

    function handleResize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        if(tiers && user) {
            var increment = Math.round(user.points * percent);
        
            if(pointsCounters.main <= user.points) {
                setTimeout(animationThread, (1000 / 60));
            }
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    });

    function animationThread() {
        if(tiers && user) {
            var increment = Math.round(user.points * percent);
            //var increment = user.points * percent;
            //modify by the tier
            var tier = findCurrentTierByPoints();

            //increment = increment * (((tier) + 1) * 0.27);
            //increment = Math.round(increment);

            //TODO: UPDATE THE ANIMATION

            var points = Object.assign({}, pointsCounters);

            if(points.main + increment < user.points) {
                points.main += increment;

                //calculate the tier up increment
                increment = Math.round(user.points_current_year * percent);
                points.yearly += increment;

                //now we need to deal with the points to the next tier
                //this will be the next tier requirements - the current main points counter
                points.nextTier = tiers[findCurrentTierByPoints()].highend - points.main;
                //if(points.nextTier < 0) points.nextTier = 0;

                setPointsCounters(points);
            }
            else {
                points.main = user.points;
                points.yearly = user.points_current_year;
                points.nextTier = tiers[currentTier+1].lowend - user.points;
                if(points.nextTier < 0) points.nextTier = 0;

                setPointsCounters(points);
            }
        }
    }

    function findCurrentTierByPoints() {
        var t = 0;

        for(var i = 0; i < tiers.length; i++) {
            if(pointsCounters.main >= parseInt(tiers[i].lowend) && pointsCounters.main < parseInt(tiers[i].highend)) {
                t = i;
            }
        }

        return t;
    }

    function getUserTier() {
        var userTier = 0;
        for(var i = 0; i < tiers.length; i++) {
            if(tiers[i].name === user.tier) userTier = i;
        }

        return userTier;
    }

    function DashboardSummerySlider(props) {
        //var calcNum = props.points;
        //var calcMax = props.currentTier.highend;

        var calcNum = props.points - props.currentTier.lowend;
        var calcMax = props.currentTier.highend - props.currentTier.lowend;
        var tierColour = props.currentTier.tier_colour;
 
        var percent = calcNum / calcMax;
        percent = percent * 100;
        if(percent < 1) percent = 1;

        var sliderStyles = {
            left: (percent)+"%"
        };
    
        var colourStyles = {
            width: (percent)+"%",
            backgroundColor: tierColour
        };

        var ovalStyles = {
            backgroundColor: tierColour
        };
    
        return(
            <div className="DashboardSummerySlider">
                <div className="oval" style={ovalStyles}></div>
                <div className="line"></div>
                <div className="line-colour-cover" style={colourStyles}></div>
                <div className="overlay-points" style={sliderStyles}>
                    {addCommas(props.points)}
                </div>
                <div className="end-oval">
                    <div className="inner-piece"></div>
                </div>
            </div>
        )
    }

    function topRightInfo() {
        return (
            <React.Fragment>
                <div className="points-to">Points accumulated in {new Date().getFullYear()}</div>
                <div className="points-to-number">
                    {addCommas(parseInt(pointsCounters.yearly))}
                </div>
            </React.Fragment>
        )
    }

    function tierInfo() {
        var userTier = getUserTier();
        var tierName = tiers[currentTier].name;
        var tierSrc = tiers[currentTier].icon.url;

        if(userTier === tiers.length -2) {
            tierName = tiers[userTier].name;
            tierSrc = tiers[userTier].icon.url;
        }

        return (
            <React.Fragment>
                <h3>{tierName} Tier</h3>
                <img src={tierSrc} />
            </React.Fragment>
        )
    }

    function sliderSection() {
        var userTier = getUserTier();

        if (userTier === tiers?.length - 2) {
            return (
                <React.Fragment>
                    <div className="tier-overview-nobar">
                        {tiers[userTier].dashboard_summary_bottom_text}
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="slider-container">
                        {tiers && user &&
                            <DashboardSummerySlider
                                points={pointsCounters.main}
                                toNext={toNextTier}
                                currentTier={tiers[currentTier]}
                            />
                        }
                    </div>
                    <div className="tier-overview-container">
                        <div className="this-tier">
                            {tiers && tiers[currentTier].name}
                            <span>{tiers && addCommas(tiers[currentTier].lowend)}</span>
                        </div>
                        <div className="next-tier">
                            {addCommas(pointsCounters.nextTier)} Points to <strong>{tiers && tiers[currentTier+1]?.name}</strong>
                            <span>{tiers && addCommas((parseInt(tiers[currentTier].highend))+1)}</span>
                        </div>
                    </div>
                </React.Fragment>
            )
        }

    }
    
    return (
        <div className="DashboardSummary">
            <div className="icon-container">
                <div className="tier-wrapper">
                    {tierInfo()}
                </div>
                <div className="points-container">
                    <h4>Your Total Points</h4>
                    <div className="points-total">{
                        user?.points && addCommas(pointsCounters.main)
                    }</div>
                </div>
                <img className='arrow' src="/img/dashboard-pointer-arrow.svg" />
            </div>
            <div className="points-to-container">
                {topRightInfo()}
                <ArrowLink to="/tier-details" label="View Tier Details"/>
            </div>
            {sliderSection()}
        </div>
    );
}

export default DashboardSummary;