import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LeftSidebar from '../LeftSidebar/LeftSidebar';
import ContentLightBlueWrapper from '../ContentLightBlueWrapper/ContentLightBlueWrapper';
import './FAQ.scss';
import FAQQuestion from './FAQQuestion';

function FAQ() {
	const content = useSelector((state) => state.content.pages.faq);
    var [open, setOpen] = useState(-1);

    function updateOpen(index) {
        var value = -1;
        if(index !== open) value = index;
        setOpen(value);
    }

    return (
        <div className="FAQ">
            <LeftSidebar />
            <ContentLightBlueWrapper>
                <div className="faq-content-container">
                    <h1>{content?.post_title}</h1>
                    <div className="faq-wrapper">
                        {content?.faq.map((faq, key) => (
                            <FAQQuestion
                                id={key}
                                key={key}
                                index={open}
                                open={key === open}
                                answer={faq.answer}
                                callback={updateOpen}
                                question={faq.question}
                            />
                        ))}
                    </div>
                </div>
            </ContentLightBlueWrapper>
        </div>
    );
}

export default FAQ;
