import React from 'react';
import './Col.scss';

function Col(props) {
    return <div className={'Col columns' + (props.className ? ' ' + props.className : '')}>
				 {props.children}
			</div>;
}

export default Col;
