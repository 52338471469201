import "./LoadingCover.scss";
import React, { useEffect, useState } from "react";

function LoadingCover() {
    var [winWidth, setWinWidth] = useState(window.innerWidth);

    function updateWinWidth() {
        setWinWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', updateWinWidth);
        return () => window.removeEventListener('resize', updateWinWidth);
    });

    return (
        <div className="LoadingCover">
            <div className="left-side-bar-loader">
                {winWidth > 899 &&
                    <React.Fragment>
                        <div className="preview-cover"></div>
                        <div className="preview-cover"></div>
                        <div className="preview-cover"></div>
                        <div className="preview-cover auto-margin"></div>
                        
                        <div className="preview-cover sub-item"></div>
                        <div className="preview-cover sub-item"></div>
                        <div className="preview-cover sub-item big-space"></div>

                        <div className="preview-cover small-item"></div>
                        <div className="preview-cover small-item bottom-space"></div>
                    </React.Fragment>
                }
            </div>
            <div className="content-loader"></div>
            <div className="right-side-bar-loader">
                {winWidth > 1024 &&
                    <React.Fragment>
                        <div className="preview-cover"></div>
                        <div className="preview-cover"></div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}

export default LoadingCover;