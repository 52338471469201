import "./ProjectBackArrow.scss";
import React from 'react';
import { Link } from 'react-router-dom';

function ProjectBackArrow(props) {

    function ProjectArrowSVG(props) {
        return(
            <svg width={8} height={12} xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M6.094 11.836L.572 6.398a.567.567 0 01-.004-.796L6.09.164c.22-.22.576-.22.796 0l.333.333c.22.22.22.576 0 .797L2.43 6l4.795 4.706c.22.22.22.577 0 .797l-.333.333a.563.563 0 01-.797 0z"
                    fill="#C2CCD4"
                    fillRule="nonzero"
                />
            </svg>
        )
    }

    return(
        <Link to={props.to} className="ProjectBackArrow">
            <ProjectArrowSVG />
            {props.text}
        </Link>
    )
}

export default ProjectBackArrow;