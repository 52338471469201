import "./ProfilePreferences.scss";
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import ProfilePreferencesForm from "./ProfilePreferencesForm";
import FormReqStar from "../Form/FormReqStar";
import ProfileBackSection from "../Profile/ProfileBackSection";

function ProfilePreferences() {
    var [width, setWidth] = useState(window.innerWidth);

    var pageContent = useSelector(
        state => state.content.pages.profile_preferences
    );

    function updateSize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', updateSize);

        return () => {
            window.removeEventListener('resize', updateSize);
        }
    });

    return (
        <div className="ProfilePreferences">
            {width < 481 &&
                <ProfileBackSection image="/img/small-preference-icon.svg" alt="" />
            }
            {width > 480 &&
                <img src="/img/email-preference-icon.svg" alt="" className="email-image" />
            }
            <div className="form-wrapper">
                {pageContent?.form &&
                    <ProfilePreferencesForm form={pageContent.form} />
                }
            </div>
            <div className="required-fields">
                <FormReqStar nospace />&nbsp;Required
            </div>
        </div>
    );
}

export default ProfilePreferences;