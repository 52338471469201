import React from 'react';
import { useSelector } from 'react-redux';
import './LoginLeft.scss';

function LoginLeft() {
	const content = useSelector((state) => state.content.pages?.home);

    return (
        <div className="LoginLeft">
            <div className="content-wrapper">
                <h1>{content?.intro?.heading}</h1>
                <h2>{content?.intro?.bold_copy}</h2>
                <p className="body-copy">{content?.intro?.copy}</p>
            </div>
        </div>
    );
}

export default LoginLeft;
