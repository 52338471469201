import './LeftSideBarSubNav.scss';
import React from 'react';
import { Link } from 'react-router-dom';

function LeftSideBarSubNav(props) {
    function classesByActive(link) {
        return props.location.pathname === link ? "active" : "";
    }

    return (
        <div className="LeftSideBarSubNav">
            <Link to="/builder-story" className={classesByActive('/builder-story')}>Builder Story</Link><br/>
            <Link to="/tier-details" className={classesByActive('/tier-details')}>Tier Details</Link><br/>
            <Link to="/faq" className={classesByActive('/faq')}>FAQ</Link>
        </div>
    )
}

export default LeftSideBarSubNav;